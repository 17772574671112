import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { InputLabel } from "@mui/material";
import CustomInput from "../UI/CustomInput/CustomInput";
import { ltrim } from "../../utils/helperFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../UI/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import { setAuditorOne } from "../../redux/DashBoardReducer/DashBoardAction";

const AuditorModal = ({ saveAuditorOne }) => {
  const {
    getEmailAuditorOneError,
    getEmailAuditorOne,
    emailLoading,
    auditor_one_email_success,
    auditor_one_email_error,
    o_IdList,
  } = useSelector((state) => state?.DashBoard);
  const dispatch = useDispatch();
  const initError = {
    auditorError: "",
  };
  const initData = {
    auditor: "",
  };
  const [dataError, setDataError] = useState(initError);
  const [data, setData] = useState(initData);

  const auditorOneDebounce = useDebounce(data.auditor, 2000);

  useEffect(() => {
    if (data.auditor)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.auditor,
          checkTypeError: "auditor_one_email_error",
          oidColumnName: "auditor_one",
          checkTypeSuccess: "auditor_one_email_success",
        })
      );
  }, [auditorOneDebounce]);

  const setErrorHandler = (type, message) => {
    switch (type) {
      case "auditor":
        return {
          ...dataError,
          auditorError: message,
        };
      default:
        return {
          ...dataError,
        };
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setData({
      ...data,
      [name]: value,
    });
    if (getEmailAuditorOneError)
      dispatch({
        type: "GET_AUDITOR_ONE_EMAIL_ERROR",
        payload: "",
      });
    if (value) {
      setDataError(setErrorHandler(name, ""));
    } else {
      setDataError(setErrorHandler(name, "Please enter some valid input."));
    }
  };

  const { auditor } = data;
  const { auditorError } = dataError;
  const isValidForm = () => {
    let error = {
      ...dataError,
    };
    if (!ltrim(auditor))
      error = {
        ...error,
        auditorError: "Please enter some valid input.",
      };
    setDataError(error);

    if (!!ltrim(auditor)) {
      saveAuditorOne(data.auditor);
    }
  };

  useEffect(() => {
    setDataError({
      ...dataError,
      auditorError: getEmailAuditorOneError,
    });
  }, [getEmailAuditorOneError]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "RESET_LOCATION_VALIDATION_INPUT",
      });
    };
  }, []);

  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Enter Auditor
          </InputLabel>
          <CustomInput
            name="auditor"
            value={auditor || ""}
            placeholder="Enter here..."
            onChange={handleChange}
            error={auditorError || auditor_one_email_error}
            success={auditor_one_email_success}
          />
          {(auditorError || auditor_one_email_error) && (
            <small className="input-error-message">
              {auditorError || auditor_one_email_error}
            </small>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center" className="mt-6 mb-2">
        <Grid item xs={12}>
          <CustomButton
            padding={{ md: "0.5rem 2rem" }}
            style={{ borderRadius: 35, fontWeight: "bold" }}
            width="100%"
            disabled={auditorError || auditor_one_email_error}
            onClick={isValidForm}
          >
            {emailLoading ? <CircularProgress size={25} /> : "SUBMIT"}
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};
export default AuditorModal;
