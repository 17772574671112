/* eslint-disable no-unused-vars */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./DashboardTable.scss";
import { YELLOW } from "../../Colors/Colors";
import CustomButton from "../UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoDataPlaceholder from "../UI/NoDataPlaceholder";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import {
  getActionLogScores,
  getVulnerabilityScoreScores,
} from "../../utils/helperFunctions";

function createData(
  id,
  country,
  location,
  locationType,
  vulnerabilityScore,
  actionLog,
  answeredQuestions,
  unAnsweredQuestions,
  zone,
  bu,
  action
) {
  return {
    id,
    country,
    location,
    locationType,
    vulnerabilityScore,
    actionLog,
    answeredQuestions,
    unAnsweredQuestions,
    zone,
    bu,
  };
}
const getScore = (quesList = []) => {
  const perimeterArray = quesList.filter(q => q?.section === 'Perimeter') || []
  const filterArray = quesList.filter(q => q?.section !== 'Perimeter') || []
  if (!perimeterArray[0]?.answer) {
    return [...filterArray, perimeterArray[0]]
  }
  if (perimeterArray[0]?.answer === 'Yes') {
    if (perimeterArray[1]?.answer === 'Yes') {
      const filteredData = perimeterArray?.slice(0, 2) || []
      return [...filterArray, ...filteredData]
    } else if (!perimeterArray[1]?.answer) {
      return [...filterArray, perimeterArray[0], perimeterArray[1]]
    } else if (perimeterArray[1]?.answer === 'No') {
      return [...filterArray, ...perimeterArray]
    }
  } else if (perimeterArray[0]?.answer === 'No') {
    perimeterArray?.splice(1, 1)
    return [...filterArray, ...perimeterArray]
  }

}
const DashboardTable = ({ tableData = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state?.Auth);
  const rows = tableData?.map((i, index) => {
    const randomValue = Math.floor(Math.random() * 10);
    const questionPendingFilterData = getScore(i?.questionList?.filter((s) => s.is_sub_question === "No"));
    const perimeterArrayData = questionPendingFilterData?.filter(q => q?.section === 'Perimeter')?.map(i => i.id) || []
    const allperimeter = i?.questionList?.filter(q => q?.section === 'Perimeter')?.map(i => i.id) || []
    const questionAnswered =
      i.questionAnsList?.filter((s) => s?.answer && s?.is_sub_question === "No" && (!allperimeter.includes(s?.question_id) || perimeterArrayData.includes(s.question_id)))?.length || 0;

    const questionPending = questionPendingFilterData?.length - questionAnswered;
    return createData(
      index,
      i.country,
      i.location,
      i.location_type,
      questionAnswered
        ? getVulnerabilityScoreScores(i.questionAnsList, i.questionList, true)
        : "N/A",
      questionAnswered ? getActionLogScores(i.questionAnsList.filter(i => i.answer === 'No')) : "N/A",
      questionAnswered || 0,
      Math.abs(questionPending) || 0,
      i.zone,
      i.business_unit,
    );
  });
  const navigateToQuestionnaire = (location, zone, row) => {
    dispatch({
      type: "SET_COUNTRY_ZONE",
      payload: row.zone,
    });

    dispatch({
      type: "SET_BU",
      payload: row.bu,
    });

    dispatch({
      type: "SELECT_ZONE",
      payload: zone,
    });
    dispatch({
      type: "SELECT_LOCATION",
      payload: location,
    });
    const userType = roles?.toLowerCase();
    navigate(`/${userType}/questionnaire`);
  };

  const getAnsweredText = (answeredQuestions, unAnsweredQuestions) => {
    if (answeredQuestions === 0) return "gray";
    if (unAnsweredQuestions === 0) return "green";
    return "rgb(220 171 21)";
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sx={{ height: 500, width: "100%", pl: 5, pr: 5 }}
      >
        <DataGrid
          sx={{ width: "100%" }}
          rows={rows}
          className="remove-search-boarder"
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          columns={[
            {
              field: "country",
              headerName: "Country",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 120,
            },
            {
              field: "location",
              headerName: "Location",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 180,
            },

            {
              field: "locationType",
              headerName: "Location Type",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 100,
            },
            {
              field: "vulnerabilityScore",
              headerName: "Location Vulnerability Score",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 130,
              renderCell: (row) => {
                return (
                  <span>
                    {row.row.unAnsweredQuestions === 0
                      ? row.row.vulnerabilityScore
                      : "N/A"}
                  </span>
                );
              },
            },
            {
              field: "actionLog",
              headerName: "Action Log Completion",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 155,
              renderCell: (row) => {
                return (
                  <span>
                    {row.row.unAnsweredQuestions === 0
                      ? row.row.actionLog
                      : "N/A"}
                  </span>
                );
              },
            },
            {
              field: "answeredQuestions",
              headerName: "Questions Answered",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 135,
              renderCell: (row) => {
                return (
                  <span
                    style={{
                      color: getAnsweredText(
                        row.row.answeredQuestions,
                        row.row.unAnsweredQuestions
                      ),
                    }}
                  >
                    {row.row.answeredQuestions}
                  </span>
                );
              },
            },

            {
              field: "unAnsweredQuestions",
              headerName: "Questions Pending To Be Answered",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 180,
              renderCell: (row) => {
                return (
                  <span
                    style={{
                      color: getAnsweredText(
                        row.row.answeredQuestions,
                        row.row.unAnsweredQuestions
                      ),
                    }}
                  >
                    {" "}
                    {row.row.unAnsweredQuestions}
                  </span>
                );
              },
            },
            {
              headerName: "Take Action",
              flex: 1,
              cellClassName: "dashboardCell",
              minWidth: 180,
              renderCell: (row) => {
                return (
                  <CustomButton
                    onClick={() =>
                      navigateToQuestionnaire(
                        row.row.location,
                        row.row.locationType,
                        row.row
                      )
                    }
                  >
                    Action
                  </CustomButton>
                );
              },
            },
          ]}
          // pageSize={10}
          // rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick
        />
      </Grid>
    </>
  );
};

export default DashboardTable;
