import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Login = lazy(() => import("../components/Login/Login"));
const NotFOund = lazy(() => import('../components/NoMatch/NoMatch'))

const LoggedOutRoutes = () => {
    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route exact path='/' element={<Login />} />
                <Route exact path={`*`} element={<NotFOund />} />
            </Routes>
        </Suspense>
    )
}

export default LoggedOutRoutes