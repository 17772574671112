import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { AuthReducer } from "./AuthReducer/AuthReducer";
import { DashBoardReducer } from "./DashBoardReducer/DashBoardReducer";
import { UtilityReducer } from "./UtilityReducer/UtilityReducer";

const RootReducer = combineReducers({
  Auth: AuthReducer,
  DashBoard: DashBoardReducer,
  Util: UtilityReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const Store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(Store);
