/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Grid from "@mui/material/Grid";
import { RED, YELLOW } from "../../Colors/Colors";
import StatsCard from "../../components/StatsCard";
import { Container } from "@mui/material";
import ActionLogTable from "../../components/ActionLogTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getApproverActionLogs,
  getDashBoardData,
  getQuestions,
  getQuestionTabs,
} from "../../redux/DashBoardReducer/DashBoardAction";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getActionLogScores,
  getFilterdData,
  getSumValues,
  getVulnerabilityScoreScores,
  locationFormatter,
} from "../../utils/helperFunctions";
import ApproverActionLogTable from "../../components/ActionLogTable/ApproverActionLogTable";

function ApproverActionLog() {
  // selector
  const {
    approverActionLogs = [],
    loading,
    questions = [],
    selectedLocation = "",
    selectedZone = "",
    listOfZones = [],
    listOfLocations = [],
    dashBoardData = [],
  } = useSelector((state) => state?.DashBoard);
  const { user, roles } = useSelector((state) => state?.Auth);
  const dispatch = useDispatch();

  const [scorList, setScorList] = useState({
    vulnerabilityScore: "0",
    actionLog: "0",
    GITeCAndZIT: "0",
  });

  useEffect(() => {
    if (user?.mail)
      dispatch(getApproverActionLogs(selectedLocation, selectedZone));
  }, [user?.mail?.toLowerCase(), selectedZone, selectedLocation]);

  useEffect(() => {
    if (!questions.length && user?.mail)
      dispatch(
        getQuestions(
          user?.id,
          user?.mail?.toLowerCase(),
          selectedLocation,
          selectedZone,
          roles,
          dashBoardData?.filter(
            (item) => item?.location === selectedLocation
          )?.[0]?.country
        )
      );
  }, [user?.mail?.toLowerCase()]);

  useEffect(() => {
    if (questions.length) {
      // *********************************************************************************
      // vulnerabilityScore

      const vulnerabilityScore = getVulnerabilityScoreScores(questions);

      const questionSet=questions?.filter(
        (item) =>
          item?.zone === locationFormatter(selectedZone)
      );
      // *****************************************************************************
      // "Innovation" --section
      const innovationAllSectionData = getFilterdData(
        questionSet,
        "section",
        "Innovation"
      );
      const yesInnovationAllSection = getFilterdData(
        innovationAllSectionData,
        "answer",
        "Yes"
      );
      const innovationWeightage = getSumValues(
        innovationAllSectionData,
        "weightage"
      );
      const yesInnovationWeightage = getSumValues(
        yesInnovationAllSection,
        "weightage"
      );
      const GITeCAndZIT = (
        (yesInnovationWeightage / innovationWeightage) *
        100
      ).toFixed(2);

      console.log(scorList);

      setScorList({
        ...scorList,
        vulnerabilityScore: getVulnerabilityScoreScores(questions),
        GITeCAndZIT,
        actionLog: getActionLogScores(approverActionLogs),
      });
    }
  }, [questions, approverActionLogs]);

  useEffect(() => {
    if (selectedLocation === "") {
      // setActionLogsTableData(
      //   actionLogs?.filter((item) => item?.zone === listOfZones[0])
      // );

      dispatch({
        type: "SELECT_LOCATION",
        payload: listOfLocations[0]?.location,
      });
      dispatch({
        type: "SELECT_ZONE",
        payload: listOfZones[0],
      });
    } else {
      // setActionLogsTableData(actionLogs?.filter((item) => item?.zone === selectedZone));
    }
  }, [approverActionLogs, listOfLocations]);

  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <CircularProgress style={{ color: YELLOW }} />
        </div>
      ) : (
        <div className="pt-5">
          <Grid container sx={{ p: 1 }}>
            {/* {roles === "Auditor" && (
              <StatsCard title="Section Score" value={25} color={YELLOW} />
            )} */}
            <StatsCard
              title="Location Vulnerability Score"
              value={`${scorList.vulnerabilityScore}`}
              color={YELLOW}
            />
            <StatsCard
              title="Action Log Completion"
              value={`${scorList.actionLog}`}
              color={YELLOW}
            />
            {roles === "Auditor" && (
              <StatsCard
                title="GITeC & ZITec Sction Score"
                value={`${scorList.GITeCAndZIT}%`}
                color={YELLOW}
              />
            )}
          </Grid>
          <ApproverActionLogTable />
        </div>
      )}
    </>
  );
}

export default ApproverActionLog;
