import React from "react";
import { Grid, Typography } from "@mui/material";
const StatsCard = ({ title, value, color }) => {
  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        padding: 1,
        mt: {
          xs: 1,
          md: 2,
        },
        mb: {
          xs: 1,
          md: 2,
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          margin: "auto",
          backgroundColor: "#000",
          color: "#fff",
          padding: "0.75rem 1.8rem",
          borderRadius: "4px",
          // width: {
          //   xs: "100%",
          //   md: "auto",
          // },
          width: "100%",
          fontSize: "14px",
        }}
      >
        <span style={{ color: color }}>{title}</span> : {value}
      </Typography>
    </Grid>
  );
};
export default StatsCard;
