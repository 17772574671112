const initialState = {
  dashBoardData: [],
  questionsTab: [],
  questions: [],
  listOfZones: [],
  listOfLocations: [],
  selectedZone: "",
  selectedLocation: "",
  actionLogs: [],
  approverActionLogs: [],
  loading: false,
  emailLoading: false,
  getEmailAuditorOne: {},
  selectedCountry: null,
  selectedCountryLocation: null,
  countryList: [],
  countryLocationList: [],
  locationArray: [],
  selectedCountryZone: "",
  selectedBU: "",
  inventory: [],
  inventoryTotalData: 0,
  inventoryOffset: 0,
  emailUpdated: false,
  adminLocationList: {},
  addNewLocationLoading: false,
  // admin only
  businessUnit: [],
  country: [],
  o_IdList: {},
  newLocationData: {},
  mailSent: false,
  mailSentError: "",
  errorData: {},
};

export const DashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_MAIL_SENT_ERROR":
      return {
        ...state,
        mailSentError: action.payload.error,
        errorData: action.payload.errorData
      };
    case "SET_MAIL_SENT":
      return {
        ...state,
        mailSent: action.payload,
      };
    case "SET_NEW_LOCATION_DATA":
      return {
        ...state,
        loading: action.payload,
      };

    case "SET_O_ID_LIST":
      return {
        ...state,
        o_IdList: action.payload,
      };
    case "SET_EMAIL_UPDATION":
      return {
        ...state,
        emailUpdated: action.payload,
      };
    case "GET_ADMIN_LOCATION":
      return {
        ...state,
        adminLocationList: action.payload,
      };
    case "SET_AUDITOR_ONE_EMAIL_LOADING":
      return {
        ...state,
        emailLoading: action.payload,
      };

    case "SET_COUNTRY_ZONE":
      return {
        ...state,
        selectedCountryZone: action.payload,
      };
    case "SET_BU":
      return {
        ...state,
        selectedBU: action.payload,
      };
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        dashBoardData: action.payload,
      };
    case "GET_QUESTION_TABS":
      return {
        ...state,
        questionsTab: action.payload,
      };
    case "GET_QUESTION":
      return {
        ...state,
        questions: action.payload,
      };
    case "SET_LIST_OF_ZONES":
      return {
        ...state,
        listOfZones: action.payload,
      };
    case "SET_LIST_OF_LOCATIONS":
      return {
        ...state,
        listOfLocations: action.payload,
      };
    case "SET_LOCATIONS":
      return {
        ...state,
        locationArray: action.payload,
      };
    case "SELECT_ZONE":
      // let lt = action.payload;
      // let location_type = "";
      // if (lt === "Distribution Center") {
      //   location_type = "Distribution Centre";
      // } else if (lt === "Office") {
      //   location_type = "Office";
      // } else if (
      //   lt === "ZX office" ||
      //   lt === "ZX Craft brewery" ||
      //   lt === "ZX brew pub" ||
      //   lt === "ZX Warehouse"
      // ) {
      //   location_type = "Craft  ZX BrewPub Breweries";
      // } else if (lt === "Vertical ops industrial") {
      //   location_type = "Vertical Operations  Industrial";
      // } else if (lt === "Vertical ops agriculture") {
      //   location_type = "Vertical Operations  Agricultural";
      // } else {
      //   location_type = lt;
      // }
      return {
        ...state,
        selectedZone: action.payload,
      };
    case "SELECT_LOCATION":
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case "GET_ACTION_LOG_DATA":
      return {
        ...state,
        actionLogs: action.payload,
      };

    case "GET_APPROVER_ACTION_LOG_DATA":
      return {
        ...state,
        approverActionLogs: action.payload,
      };

    case "SET_SELECTED_COUNTRY":
      return {
        ...state,
        selectedCountry: action.payload,
      };

    case "SET_SELECTED_COUNTRY_LOCATION":
      return {
        ...state,
        selectedCountryLocation: action.payload,
      };

    case "SET_SELECTED_COUNTRY_LIST":
      return {
        ...state,
        countryList: action.payload,
      };

    case "SET_SELECTED_COUNTRY_LOCATION_LIST":
      return {
        ...state,
        countryLocationList: action.payload,
      };
    case "GET_INVENTORY":
      return {
        ...state,
        inventory: action.payload?.inventory,
        inventoryTotalData: action.payload?.totalData,
      };

    case "SET_INVENTORY_OFFSET":
      return {
        ...state,
        inventoryOffset: action.payload,
      };
    case "GET_AUDITOR_ONE_EMAIL":
      return {
        ...state,
        getEmailAuditorOne: action.payload,
      };
    case "GET_AUDITOR_ONE_EMAIL_ERROR":
      return {
        ...state,
        getEmailAuditorOneError: action.payload,
      };
    case "GET_AUDITOR_EMAIL_TYPE_ERROR":
      return {
        ...state,
        [action.payload.type]: action.payload.message,
      };
    case "GET_AUDITOR_EMAIL_TYPE_VALID":
      return {
        ...state,
        [action.payload.type]: action.payload.message,
      };

    case "RESET_LOCATION_VALIDATION_INPUT":
      return {
        ...state,
        reviewer_email_error: "",
        reviewer_email_success: "",
        approver_email_error: "",
        approver_email_success: "",
        auditor_one_email_error: "",
        auditor_one_email_success: "",
        auditor_two_email_error: "",
        auditor_two_email_success: "",
        auditor_three_email_error: "",
        auditor_three_email_success: "",
        auditor_four_email_error: "",
        auditor_four_email_success: "",
      };

    case "ADD_NEW_LOCATION_LOADING":
      return {
        ...state,
        addNewLocationLoading: action.payload,
      };
    case "LOCATION_ACCESS_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
