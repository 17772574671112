import React, { useEffect, useState } from "react";
import CustomButton from "../../components/UI/CustomButton";
import PlusIcon from "../../assets/Images/svg/Plus";
import { Container } from "react-bootstrap";
import "./styles.scss";
import AdminDashboardTable from "../../components/DashboardTable/AdminDashboardTable";
import CustomDialogs from "../../components/UI/CustomDialogs";
import AddLocationModal from "../../components/AddLocationModal";
import AuditorModal from "../../components/AuditorModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewLocation,
  getAdminLocationList,
  getInventory,
  searchInventory,
  setAuditorOne,
} from "../../redux/DashBoardReducer/DashBoardAction";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import ErrorAlert from "../../components/ErrorAlert";
import CustomInput from "../../components/UI/CustomInput/CustomInput";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({
    search: ""
  });
  const [selectionId, setSelectionId] = React.useState([]);
  const {
    inventory = [],
    inventoryOffset,
    emailUpdated,
    adminLocationList,
    o_IdList,
    mailSentError,
    errorData
  } = useSelector((state) => state?.DashBoard);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (emailUpdated) {
      setOpen(null);
      dispatch({
        type: "SET_EMAIL_UPDATION",
        payload: false,
      });
    }
  }, [emailUpdated]);

  useEffect(() => {
    if (mailSentError) {
      return setOpenModal(true)
    }
  }, [mailSentError])

  const saveAuditorOne = (data) => {
    const roleType = open?.split("_")[0];
    const role = roleType.charAt(0).toUpperCase() + roleType.slice(1);
    dispatch(
      setAuditorOne({
        email: data,
        id: selectionId,
        role: role,
        columnName: open,
        oidColumnName: `${open}_oid`,
        inventoryOffset,
      })
    );
    setSelectionId([]);
  };

  const saveNewLocation = (data) => {
    const param = {
      ...data,
      ...o_IdList,
      bu: data.business_unit,
    }
    dispatch(addNewLocation({ data: param, inventoryOffset }));
    // setRows([{ ...data, id: new Date().getTime() }, ...rows]);
    // setOpen(null);
  };

  useEffect(() => {
    if (!data.search) {
      dispatch(getInventory({ offset: inventoryOffset }));
    } else {
      dispatch(searchInventory({ keyWord: data.search }));
    }
    dispatch(getAdminLocationList());
  }, [inventoryOffset]);

  useEffect(() => {
    setRows(inventory);
  }, [inventory]);

  const handleCloseModal = () => {
    dispatch({
      type: "SET_MAIL_SENT_ERROR",
      payload: {
        error: '',
        errorData: {}
      },
    });
    setOpenModal(false);
  };

  const handleChange = ({ target: { value, name } }) => {
    setData({
      ...data,
      [name]: value,
    });
  }
  const onKeyDown=(e)=>{
    if(e.key==='Enter'){
      dispatch(searchInventory({ keyWord: data.search }));
    }
  }
  return (
    <div>
      <ErrorAlert
        open={openModal}
        handleClose={handleCloseModal}
        errorData={errorData}
      />
      <Container className="mt-7">
        <div className="btn-wrapper-top-bar">
          <CustomButton
            startIcon={<PlusIcon className="" />}
            padding={{ md: "0.50rem 1rem" }}
            className="btn-admin-top"
            onClick={() => {
              setOpen("newLocation");
              setModalTitle("Add New Location");
            }}
          >
            <span style={{ width: "max-content" }}>New Location</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("auditor_one");
              setModalTitle("Update Auditor One");
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={() => {
                setOpenSnackbar(false);
              }}
            >
              <Alert
                onClose={() => {
                  setOpenSnackbar(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                Select any one location
              </Alert>
            </Snackbar>
            <span style={{ width: "max-content" }}>Auditor one</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("auditor_two");
              setModalTitle("Update Auditor Two");
            }}
          >
            <span style={{ width: "max-content" }}>Auditor two</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("auditor_three");
              setModalTitle("Update Auditor Three");
            }}
          >
            <span style={{ width: "max-content" }}>Auditor three</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("auditor_four");
              setModalTitle("Update Auditor Four");
            }}
          >
            <span style={{ width: "max-content" }}>Auditor four</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("reviewer");
              setModalTitle("Update Reviewer");
            }}
          >
            <span style={{ width: "max-content" }}>Reviewer</span>
          </CustomButton>
          <CustomButton
            startIcon={<PlusIcon className="" />}
            className="btn-admin-top"
            padding={{ md: "0.50rem 1rem" }}
            onClick={() => {
              if (!selectionId.length) return setOpenSnackbar(true);
              setOpen("approver");
              setModalTitle("Update Approver");
            }}
          >
            <span style={{ width: "max-content" }}>Approver</span>
          </CustomButton>
        </div>
        <div className="search-wrapper">
          <CustomInput
            name="search"
            onChange={handleChange}
            value={data.search}
            placeholder="Search here"
            onKeyDown={onKeyDown}
            // error={auditorTwoError || auditor_two_email_error}
            // success={auditor_two_email_success}
            className='inputStyle'
          />

          <CustomButton
            style={{
              padding: "6px 50px",
            }}
            onClick={() => {
              dispatch(getInventory({ offset: inventoryOffset }));
              setData({
                search: ''
              })
            }}
          >
            RESET
          </CustomButton>
        </div>
        <AdminDashboardTable
          rows={rows}
          selectionModel={selectionId}
          setSelectionModel={setSelectionId}
        />

        <CustomDialogs
          open={!!open}
          handleClose={() => setOpen(null)}
          title={modalTitle}
        >
          <div>
            {open === "newLocation" && (
              <AddLocationModal
                saveNewLocation={saveNewLocation}
                adminLocationList={adminLocationList}
              />
            )}
            {!!open && open !== "newLocation" && (
              <AuditorModal saveAuditorOne={saveAuditorOne} />
            )}
          </div>
        </CustomDialogs>
      </Container>
    </div>
  );
};
export default AdminDashboard;
