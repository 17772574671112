import React from "react";
import { NavLink } from "react-router-dom";

const ReviewerNavbar = () => {
  return (
    <>
     <li className="nav-item">
      <NavLink
        to={"/reviewer/dashboard"}
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        Dashboard
      </NavLink>
      </li>
      <li className="nav-item">
      <NavLink
        to={"/reviewer/questionnaire"}
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        Questionnaire
      </NavLink>
      </li>
      <li className="nav-item">
      <NavLink
        to={"/reviewer/action-log"}
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        Action Log
      </NavLink>
      </li>
    </>
  );
};

export default ReviewerNavbar;
