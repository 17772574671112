import { axios } from "../../utils/axios";
import { errorNotification } from "../../utils/notificationError";

export const getDashboardLocations =
  (oid, email, role, selectedCountry, selectedCountryLocation) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/location-access", {
        oid,
        email,
        role,
      });
      let locationList = [];
      let countryArray = [];
      res.data?.questionnaire?.forEach((element) => {
        const zones = res.data?.questionnaire
          .filter((i) => i.location === element.location)
          ?.map((i) => i.location_type);

        const countryLocations = res.data?.questionnaire
          .filter((i) => i.country === element.country)
          ?.map((i) => i.location);

        countryArray.push({
          country: element.country,
          countryLocations: [...new Set(countryLocations)],
        });
        const updatedIndex = locationList.findIndex(
          (i) => i.location === element.location
        );
        if (updatedIndex !== -1)
          return (locationList[updatedIndex].zones = [...zones]);
        locationList.push({
          location: element.location,
          zones,
        });
      });
      const updateCountryList = countryArray.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.country === value.country)
      );
      if (locationList.length) {
        dispatch({
          type: "SET_LIST_OF_LOCATIONS",
          payload: locationList,
        });
        dispatch({
          type: "SET_LOCATIONS",
          payload: locationList,
        });
        const updatedListLocation = locationList.find(
          (i) => i.location === selectedCountryLocation
        );
        dispatch({
          type: "SET_LIST_OF_ZONES",
          payload: selectedCountryLocation
            ? updatedListLocation?.zones
            : locationList[0]?.zones || [],
        });
      }
      if (updateCountryList.length) {
        dispatch({
          type: "SET_SELECTED_COUNTRY_LIST",
          payload: updateCountryList,
        });
        const updatedList = updateCountryList.find(
          (i) => i.country === selectedCountry
        );
        dispatch({
          type: "SET_SELECTED_COUNTRY_LOCATION_LIST",
          payload: selectedCountry
            ? updatedList?.countryLocations
            : updateCountryList[0]?.countryLocations || [],
        });
      }
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getDashBoardData =
  (oid, email, role, country, location, location_type) => async (dispatch) => {
    try {
      // const res = await pureAxios.post(
      //   "http://localhost:8080/sat/api/auditor-dashboard",
      //   {
      //     email,
      //   }
      // );
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/auditor-dashboard", {
        oid,
        email,
        role,
        country,
        location,
        location_type,
      });
      dispatch({
        type: "GET_DASHBOARD_DATA",
        payload: res.data?.questionnaire,
      });

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getQuestionTabs = (email) => async (dispatch) => {
  try {
    // const res = await pureAxios.post(
    //   "http://localhost:8080/sat/api/questionnaire-tabs",
    //   {
    //     email,
    //   }
    // );
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const res = await axios.post("/questionnaire-tabs", { email });

    dispatch({
      type: "GET_QUESTION_TABS",
      payload: res.data?.sections,
    });
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    errorNotification(error);
    console.log(error);
  }
};

export const getQuestions =
  (oid, email, location, location_type, role, country) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/questionnaire-dashboard", {
        oid,
        email,
        location,
        location_type,
        role,
        country,
      });
      console.log(res);
      dispatch({
        type: "GET_QUESTION",
        payload: res.data?.questionnaire,
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getActionLogs =
  (location, locationType, email, role, country) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/actionLogs-dashboard", {
        location,
        locationType,
        email,
        role,
        country,
      });
      console.log(res);
      dispatch({
        type: "GET_ACTION_LOG_DATA",
        payload: res?.data?.actionLog?.reverse(),
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getApproverActionLogs =
  (location, locationType) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/actionLogs-dashboard-forApprover", {
        location,
        locationType,
      });
      console.log(res);
      dispatch({
        type: "GET_APPROVER_ACTION_LOG_DATA",
        payload: res?.data?.actionLog?.reverse(),
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getInventory =
  ({ offset }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await axios.post("/inventory", {
        offset,
      });
      dispatch({
        type: "GET_INVENTORY",
        payload: res?.data,
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      dispatch({
        type: "SET_EMAIL_UPDATION",
        payload: true,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    } finally {
      dispatch({
        type: "SET_O_ID_LIST",
        payload: {},
      });
    }
  };

export const updateInventory = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    let inventoryOffset = data?.inventoryOffset;
    delete data.inventoryOffset;
    const res = await axios.post("/update-inventory", {
      ...data,
    });
    dispatch(
      getInventory({
        offset: inventoryOffset,
      })
    );
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    errorNotification(error);
    console.log(error);
  }
};

export const setAuditorOne =
  ({
    email,
    id,
    role,
    columnName,
    oidColumnName,
    inventoryOffset,
    checkTypeError,
    newLocation,
    checkTypeSuccess,
    oIDParams,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "SET_AUDITOR_ONE_EMAIL_LOADING",
        payload: true,
      });
      const headers = {
        ConsistencyLevel: "eventual",
        common: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const param = {
        ...oIDParams,
      };
      const res = await axios.get(
        `https://graph.microsoft.com/v1.0/users?$select=id,displayName,userPrincipalName,mail,userType,onPremisesSyncEnabled,identities,companyName,creationType&$search=("mail:${email}" OR "userPrincipalName:${email}")&$top=1&$count=true`,
        {
          headers,
        }
      );
      dispatch({
        type: "SET_AUDITOR_ONE_EMAIL_LOADING",
        payload: false,
      });

      if (res?.data["@odata.count"] === 0) {
        if (checkTypeError || checkTypeSuccess) {
          dispatch({
            type: "GET_AUDITOR_EMAIL_TYPE_ERROR",
            payload: {
              type: checkTypeError,
              message: "This email not valid...",
            },
          });
          dispatch({
            type: "GET_AUDITOR_EMAIL_TYPE_VALID",
            payload: {
              type: checkTypeSuccess,
              message: "",
            },
          });
          return;
        }
        dispatch({
          type: "GET_AUDITOR_ONE_EMAIL_ERROR",
          payload: "Enter a valid email",
        });
      } else {
        if (!id) {
          dispatch({
            type: "GET_AUDITOR_EMAIL_TYPE_ERROR",
            payload: {
              type: checkTypeError,
              message: "",
            },
          });
          dispatch({
            type: "GET_AUDITOR_EMAIL_TYPE_VALID",
            payload: {
              type: checkTypeSuccess,
              message: "This email valid...",
            },
          });
        }
        const { data: { value = [] } = {} } = res;

        if (newLocation) {
          param[`${oidColumnName}_oid`] = value[0].id;
          param[oidColumnName] = value[0].mail;
          dispatch({
            type: "SET_O_ID_LIST",
            payload: param,
          });
        }
        if (value.length > 0 && id)
          dispatch(
            updateInventory({
              id,
              role,
              columnName,
              oidColumnName,
              objectID: value[0]?.id,
              mail: value[0]?.mail,
              inventoryOffset,
            })
          );
        dispatch({
          type: "GET_AUDITOR_ONE_EMAIL",
          payload: res?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: "SET_AUDITOR_ONE_EMAIL_LOADING",
        payload: false,
      });
      errorNotification(error);
      console.log(error);
    }
  };

export const getAdminLocationList = () => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const res = await axios.get("/fetch-data-new-location");
    const countryList = res?.data?.country.map((i) => i.country);
    const businessList = res?.data?.businessUnit.map((i) => i.business_unit);

    dispatch({
      type: "GET_ADMIN_LOCATION",
      payload: { businessList, countryList },
    });
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    errorNotification(error);
    console.log(error);
  }
};

export const addNewLocation =
  ({ data, inventoryOffset }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_NEW_LOCATION_LOADING",
        payload: true,
      });
      let errorData = data;
      const res = await axios.post("/create-inventory", {
        ...data,
      });
      if (!res?.data.status) {
        dispatch({
          type: "SET_MAIL_SENT_ERROR",
          payload: { error: res?.data?.message, errorData },
        });
      }
      dispatch({
        type: "ADD_NEW_LOCATION_LOADING",
        payload: false,
      });
      dispatch(
        getInventory({
          offset: inventoryOffset,
        })
      );
    } catch (error) {
      errorNotification(error);
      dispatch({
        type: "ADD_NEW_LOCATION_LOADING",
        payload: false,
      });
    }
  };

export const getLocationAccess = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "LOCATION_ACCESS_SUCCESS",
      payload: {
        businessUnit: [],
        country: [],
      },
    });
    const res = await axios.post("/fetch-data-new-location", {
      ...data,
    });
    dispatch({
      type: "LOCATION_ACCESS_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    errorNotification(error);
    console.log(error);
  }
};

export const requestForNewLocation = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const res = await axios.post("/request-new-location", data);
    console.log(res);

    dispatch({
      type: "SET_NEW_LOCATION_DATA",
      payload: res?.data,
    });
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    dispatch({
      type: "SET_MAIL_SENT",
      payload: true,
    });
  } catch (error) {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    errorNotification(error);
    console.log(error);
  }
};

export const searchInventory = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const res = await axios.post("/search-inventory", data);
    dispatch({
      type: "GET_INVENTORY",
      payload: res?.data,
    });
    console.log(res);
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    errorNotification(error);
    console.log(error);
  }
};
