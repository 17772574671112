/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { YELLOW } from "../../Colors/Colors";
import CustomDialogs from "../UI/CustomDialogs";
import { InputLabel, Typography } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Grid from "@mui/material/Grid";
import CustomSelect from "../UI/CustomSelect";
import CustomInput from "../UI/CustomInput/CustomInput";
import CustomButton from "../UI/CustomButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import HistoryTable from "./HistoryTable";
import { axios } from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { getActionLogs } from "../../redux/DashBoardReducer/DashBoardAction";
import { toggleSnackbar } from "../../redux/UtilityReducer/UtilityAction";
import { converToBase64, ltrim } from "../../utils/helperFunctions";
import cs from "classnames";
import { saveAs } from "file-saver";
import useWindowSize from "../../hooks/useWindowSize";
import { errorNotification } from "../../utils/notificationError";

const RowActions = ({ id, isReviewer, row, open, handleClose, isApprover }) => {
  console.log("row", row);
  const { selectedZone = "", selectedCountryLocation = "" } = useSelector(
    (state) => state?.DashBoard
  );
  const inputRef = React.useRef();
  const dispatch = useDispatch();

  const { user, roles } = useSelector((state) => state?.Auth);
  const { width } = useWindowSize();

  const [data, setData] = useState({
    actions: "",
    category: "",
    rating: "",
    budget: "",
    startDate: "",
    endDate: "",
    status: "",
    comment: "",
    document: "",
    acceptOrReject: "",
  });

  const initError = {
    actionsError: "",
    budgetError: "",
    ratingError: "",
    categoryError: "",
    startDateError: "",
    statusError: "",
    endDateError: "",
    currencyError: "",
  };
  const [dataError, setDataError] = useState(initError);
  const [historyData, setHistoryData] = useState([]);
  const [historyEntry, setHistoryEntry] = useState({});
  const [approvalAttachement, setapprovalAttachement] = useState([]);
  const [downloadAttachment, setDownloadAttachment] = useState([]);

  const { dashBoardData = [] } = useSelector((state) => state?.DashBoard);

  useEffect(() => {
    if (!open) setDataError(initError);
  }, [open]);

  const setErrorHandler = (type, message) => {
    switch (type) {
      case "actions":
        return {
          ...dataError,
          actionsError: message,
        };
      case "budget":
        return {
          ...dataError,
          budgetError: message,
        };
      case "category":
        return {
          ...dataError,
          categoryError: message,
        };
      case "startDate":
        return {
          ...dataError,
          startDateError: message,
        };
      case "rating":
        return {
          ...dataError,
          ratingError: message,
        };
      case "status":
        return {
          ...dataError,
          statusError: message,
        };
      case "endDate":
        if (row?.log_status) {
          return {
            ...dataError,
            endDateError: message,
          };
        }
        return {
          ...dataError,
        };
      default:
        return {
          ...dataError,
        };
    }
  };

  const isValidForm = () => {
    let error = {
      ...dataError,
    };

    const { actions, category, rating, budget, startDate, status, endDate } =
      data;
    if (!ltrim(actions))
      error = {
        ...error,
        actionsError: "Please enter some valid input.",
      };
    if (!ltrim(category) || category === "null")
      error = {
        ...error,
        categoryError: "Please enter some valid input.",
      };
    if (!ltrim(rating) || rating === "null")
      error = {
        ...error,
        ratingError: "Please enter some valid input.",
      };
    if (
      (!ltrim(budget + "") || !budget || budget <= 0) &&
      !["procedure", "training"].includes(data.category)
    )
      error = {
        ...error,
        budgetError: "Please enter some valid input.",
      };

    // if (!ltrim(startDate) || startDate === "null")
    //   error = {
    //     ...error,
    //     startDateError: "Please enter some valid input.",
    //   };
    // if ((!ltrim(endDate) || endDate === "null") && status === "completed")
    //   error = {
    //     ...error,
    //     endDateError: "Please enter some valid input.",
    //   };
    if (!ltrim(status) || status === "null")
      error = {
        ...error,
        statusError: "Please enter some valid input.",
      };
    setDataError(error);
    return (
      !!ltrim(actions) &&
      !(!ltrim(category) || category === "null") &&
      !(!ltrim(rating) || rating === "null") &&
      !(
        (!ltrim(budget + "") || !budget || budget <= 0) &&
        !["procedure", "training"].includes(data.category)
      ) &&
      // !(!ltrim(startDate) || startDate === "null") &&
      // !((!ltrim(endDate) || endDate === "null") && status === "completed")
      // &&
      !(!ltrim(status) || status === "null")
    );
  };

  const UploadHandeler2 = async (e) => {
    e.preventDefault();
    const validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "text/pdf",
      "text/xls",
      "text/xlsx",
    ];
    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      const fileType = file["type"];
      console.log("File Type", fileType);
      // if (validImageTypes.includes(fileType)) {
      // invalid file type code goes here.
      const base64 = await converToBase64(file);
      const base64String = await base64;
      console.log("File B64", base64String);

      const oldPhotos = [...approvalAttachement];
      const newPhotos = {
        id: Math.floor(Math.random() * 1111),
        name: file.name,
        file64: base64String,
      };
      oldPhotos.push(newPhotos);
      setapprovalAttachement(oldPhotos);
      dispatch(toggleSnackbar("Files Attached", "success"));
      // } else {
      //   dispatch(toggleSnackbar("Only JPG, PNG files are allowed", "error"));
      // }
    } else {
      const oldPhotos = [...approvalAttachement];

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const fileType = file["type"];
        if (validImageTypes.includes(fileType)) {
          const base64 = await converToBase64(file);
          const newPhotos = { name: file.name, file64: base64 };
          oldPhotos.push(newPhotos);
        } else {
          //  toast("Only JPG, PNG files are allowed");
          dispatch(toggleSnackbar("Only JPG, PNG files are allowed", "error"));
        }
      }
      setapprovalAttachement(oldPhotos);
      //  toast(`${oldPhotos.length} Photos Added`);
      dispatch(toggleSnackbar(`${oldPhotos.length} Photos Added`, "error"));
    }
  };

  useEffect(() => {
    console.log(`Fetching history for ${id}`);
    fetchHistoryData();
    fetchAttachmentData();
    setData({
      ...data,
      actions: row?.answer_to_move_yes,
      category: row?.solution_to_category,
      rating: row?.priority_rating,
      budget: row?.budget_needed?.toString() || "",
      startDate: row?.actual_start_date,
      endDate: row?.actual_end_date,
      status: row?.log_status,
    });
  }, [id]);

  const fetchHistoryData = async () => {
    try {
      const response = await axios.post("get-history-by-id", {
        id,
      });
      if (response?.data?.status) {
        setHistoryData(response?.data?.history?.reverse());
      }
    } catch (e) {
      errorNotification(e);
    }
  };

  const fetchAttachmentData = async () => {
    try {
      const response = await axios.get(`/fetch-action-log-files/${id}`);
      if (response.data) setDownloadAttachment(response.data);
      // if (response?.data?.status) {
      //   setHistoryData(response?.data?.history?.reverse());
      // }
    } catch (e) {
      errorNotification(e);
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setData({
      ...data,
      [name]: value,
    });
    setHistoryEntry({
      ...historyEntry,
      [name]: value,
    });
    if (value) {
      setDataError(setErrorHandler(name, ""));
    } else {
      setDataError(setErrorHandler(name, "Please enter some valid input."));
    }
  };

  const onClickHandler = () => {
    inputRef.current.click();
  };

  const submitLogData = async () => {
    try {
      if (isReviewer || isApprover) {
        return submitHistoryEntry();
      }
      if (!isValidForm()) {
        return;
      }
      const getEndDate = (type) => {
        switch (type) {
          case "ASAP":
            return row?.actual_start_date;
          case "Within 1 Months":
            return dayjs(row?.actual_start_date)
              .add(1, "M")
              .format("MM/DD/YYYY");
          case "Within 6 Months":
            return dayjs(row?.actual_start_date)
              .add(6, "M")
              .format("MM/DD/YYYY");
          default:
          case "Within 12 Months":
            return dayjs(row?.actual_start_date)
              .add(12, "M")
              .format("MM/DD/YYYY");
        }
      };

      const formData = {
        id,
        actions: Boolean(data?.actions) ? data?.actions : " ",
        budget: Boolean(data?.budget) ? data?.budget : "0",
        category: data?.category,
        startDate: row?.actual_start_date,
        endDate: getEndDate(data?.rating),
        status: data?.status,
        rating: data?.rating,
        images: approvalAttachement,
      };
      const resp = await axios.post("update-actionLogs-by-id", formData);
      if (resp?.data?.status) {
        dispatch(
          getActionLogs(
            selectedCountryLocation,
            selectedZone,
            user?.mail?.toLowerCase(),
            roles,
            dashBoardData?.filter(
              (item) => item?.location === selectedCountryLocation
            )?.[0]?.country
          )
        );
        dispatch(toggleSnackbar(resp?.data?.message, "success"));
        submitHistoryEntry();
        // handleClose();
      }
    } catch (e) {
      errorNotification(e);
    }
  };

  const submitHistoryEntry = async () => {
    try {
      let formData = {
        history: historyEntry,
        question_id: row?.question_id,
        role: user?.role,
        email: user?.mail,
        time: new Date(),
        action_log_id: id,
        // comment: data?.comment,
      };
      const resp = await axios.post("add-history", formData);
      if (resp?.data?.status) {
        dispatch(toggleSnackbar(resp?.data?.message, "success"));
        fetchHistoryData();
        setData({
          ...data,
          comment: "",
        });
      }
    } catch (e) {
      errorNotification(e);
      console.log(e);
    }
  };

  const downloadFiles = (fileName) => {
    let download = {
      logID: id,
      fileName: fileName + ".txt",
    };

    axios.post("download-file", download).then((res) => {
      saveAs(res.data.base, res.data.fileName);
      dispatch(toggleSnackbar("File Downloaded", "success"));
    });
  };

  const {
    actionsError,
    budgetError,
    ratingError,
    categoryError,
    startDateError,
    statusError,
    endDateError,
  } = dataError;

  return (
    <>
      <CustomDialogs
        open={open}
        handleClose={handleClose}
        title={`#${id} : Update Action Log of #Q:${row?.question_id}`}
        maxWidth={800}
      >
        <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
          Actions to move to "Yes"
        </InputLabel>
        <TextareaAutosize
          aria-label="Action"
          minRows={3}
          placeholder="Enter here..."
          style={{ width: "100%", padding: 10, borderRadius: 4 }}
          name="actions"
          onChange={handleChange}
          value={data.actions}
          disabled={
            isReviewer ||
            isApprover ||
            row?.action_log_main_status === "Accepted by approver"
          }
          className={cs({ ["input-error"]: actionsError })}
        />
        {actionsError && (
          <small className="input-error-message">{actionsError}</small>
        )}

        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12} md={4}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Solution to Category
            </InputLabel>
            <CustomSelect
              options={[
                { value: "opex", lable: "Opex" },
                { value: "capex", lable: "Capex" },
                { value: "procedure", lable: "Procedure" },
                { value: "training", lable: "Training" },
              ]}
              name="category"
              onChange={handleChange}
              value={data.category || ""}
              placeholder="SELECT"
              disabled={
                isReviewer ||
                isApprover ||
                row?.action_log_main_status === "Accepted by approver"
              }
              error={categoryError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Priority Rating
            </InputLabel>
            <CustomSelect
              options={[
                { value: "ASAP", lable: "ASAP" },
                { value: "Within 1 Months", lable: "Within 1 Months" },
                { value: "Within 6 Months", lable: "Within 6 Months" },
                { value: "Within 12 Months", lable: "Within 12 Months" },
              ]}
              name="rating"
              onChange={handleChange}
              value={data.rating || ""}
              placeholder="SELECT"
              disabled={
                isReviewer ||
                isApprover ||
                row?.action_log_main_status === "Accepted by approver"
              }
              error={ratingError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {!["procedure", "training"].includes(data.category) && (
              <>
                <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
                  Budget ($)
                </InputLabel>
                <CustomInput
                  type="number"
                  placeholder="Enter here..."
                  name="budget"
                  onChange={handleChange}
                  value={data.budget || ""}
                  disabled={
                    isReviewer ||
                    isApprover ||
                    row?.action_log_main_status === "Accepted by approver"
                  }
                  error={budgetError}
                />
                {budgetError && (
                  <small className="input-error-message">{budgetError}</small>
                )}
              </>
            )}
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Actual Start Date
            </InputLabel>
            <CustomInput
              type="date"
              placeholder="Enter here..."
              name="startDate"
              onChange={handleChange}
              value={data.startDate}
              disabled={
                isReviewer ||
                isApprover ||
                row?.action_log_main_status === "Accepted by approver"
              }
              error={startDateError}
            />
            {startDateError && (
              <small className="input-error-message">{startDateError}</small>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Actual End Date
            </InputLabel>
            <CustomInput
              type="date"
              placeholder="Enter here..."
              name="endDate"
              onChange={handleChange}
              value={data.endDate}
              disabled={
                isReviewer ||
                isApprover ||
                row?.action_log_main_status === "Accepted by approver"
              }
              error={endDateError}
            />
            {endDateError && (
              <small className="input-error-message">{endDateError}</small>
            )}
          </Grid> */}

          <Grid item xs={12} md={4}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Status
            </InputLabel>
            <CustomSelect
              options={[
                { value: "completed", lable: "Completed" },
                { value: "inProgress", lable: "In Progress" },
                { value: "delayed", lable: "Delayed" },
                { value: "not_Started_yet", lable: "Not Started Yet" },
              ]}
              name="status"
              onChange={handleChange}
              value={data.status || ""}
              placeholder="SELECT"
              disabled={
                isReviewer ||
                isApprover ||
                row?.action_log_main_status === "Accepted by approver"
              }
              error={statusError}
            />
          </Grid>
        </Grid>

        {/* {(isReviewer || isApprover) && ( */}
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12}>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
              Download Document Uploaded By Auditor
            </InputLabel>
          </Grid>

          {downloadAttachment?.length > 0 &&
            downloadAttachment?.map((item, index) => {
              let len = item.split("/").length;
              let splitData = item.split("/");
              let image = splitData[len - 1];
              return (
                <Grid item xs={4}>
                  <CustomButton
                    onClick={() => downloadFiles(image)}
                    startIcon={<DownloadIcon />}
                    width="100%"
                    padding={{
                      xs: "0.5rem 1rem",
                      md: "0.3rem 1rem",
                    }}
                  >
                    Document {index + 1}
                  </CustomButton>
                </Grid>
              );
            })}

          <Grid item xs={12}>
            <div className="border-bottom" />
          </Grid>
        </Grid>
        {/* )} */}
        <div className="mt-3">
          {isReviewer || isApprover ? (
            <>
              <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
                Add Your Comment
              </InputLabel>
              <TextareaAutosize
                aria-label="Action"
                minRows={3}
                placeholder="Enter Comment here..."
                style={{ width: "100%", padding: 10 }}
                name="comment"
                onChange={handleChange}
                value={data.comment}
              />

              {/* <InputLabel
                sm={{ mb: 2 }}
                style={{ color: "#000", marginTop: 10 }}
              >
                Accept OR Reject the Log
              </InputLabel>
              <CustomSelect
                options={[
                  { value: "accept", lable: "Accept" },
                  { value: "reject", lable: "Reject" },
                ]}
                name="acceptOrReject"
                onChange={handleChange}
                value={data.acceptOrReject}
                placeholder="SELECT"
              /> */}
            </>
          ) : (
            <>
              <InputLabel sm={{ mb: 2 }} style={{ color: "#000" }}>
                Comment
              </InputLabel>
              <TextareaAutosize
                aria-label="Action"
                minRows={3}
                placeholder="Enter here..."
                style={{ width: "100%", padding: 10 }}
                name="comment"
                onChange={handleChange}
                value={data.comment}
                disabled={
                  row?.action_log_main_status === "Accepted by approver"
                }
              />

              <div
                className={cs("mt-3 align-items-center", {
                  ["d-flex"]: width > 600,
                })}
              >
                <InputLabel
                  sm={{ mb: 2 }}
                  style={{ color: "#000", width: "180px" }}
                >
                  Upload Document
                </InputLabel>
                <input
                  ref={inputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={UploadHandeler2}
                  accept='.pdf, .png, .jpg, .jpeg, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"'
                />
                <CustomButton
                  onClick={onClickHandler}
                  startIcon={<AttachFileIcon />}
                  padding={{
                    xs: "0.5rem 2rem",
                    md: "0.3rem 2rem",
                  }}
                >
                  Attach File here
                </CustomButton>
              </div>

              {approvalAttachement?.length > 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    marginTop: "12px",
                  }}
                >
                  {approvalAttachement?.map((item, index) => (
                    <Grid
                      container
                      item
                      xs={3}
                      key={index}
                      sx={{
                        padding: "6px 12px",
                        border: `1px solid ${YELLOW}`,
                        justifyContent: "space-between",
                        borderRadius: "4px",
                        flexDirection: "column",
                        marginRight: "12px",
                      }}
                    >
                      <Typography>{item?.name}</Typography>
                      <CustomButton
                        padding={{
                          xs: "6px",
                          md: "6px",
                        }}
                        onClick={() =>
                          setapprovalAttachement(
                            approvalAttachement.filter(
                              (current) => current?.id !== item?.id
                            )
                          )
                        }
                      >
                        <i class="fa-solid fa-trash"></i>
                      </CustomButton>
                    </Grid>
                  ))}
                </Grid>
              )}

              {(!isReviewer || !isApprover) && (
                <div className="submit-wrapper my-5">
                  <CustomButton
                    className="w-full p-1"
                    onClick={submitLogData}
                    disabled={
                      row?.action_log_main_status === "Accepted by approver"
                    }
                  >
                    SUBMIT
                  </CustomButton>
                </div>
              )}
            </>
          )}
        </div>

        {(isReviewer || isApprover) && (
          <div className="mt-2 ">
            <CustomButton className="w-full p-1" onClick={submitLogData}>
              SUBMIT
            </CustomButton>
          </div>
        )}
        <div className="mt-5 mb-4">
          <div className="table-border">
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000", padding: 10 }}>
              History
            </InputLabel>
            <HistoryTable historyData={historyData} />
          </div>
        </div>
      </CustomDialogs>
    </>
  );
};
export default RowActions;
