import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import CustomSelect from "../UI/CustomSelect";
import CustomInput from "../UI/CustomInput/CustomInput";
import CustomDialogs from "../UI/CustomDialogs";
import CustomButton from "../UI/CustomButton";
import { ltrim, zoneList } from "../../utils/helperFunctions";
import "./AddLocationModal.scss";
import useDebounce from "../../hooks/useDebounce";
import {
  getLocationAccess,
  setAuditorOne,
} from "../../redux/DashBoardReducer/DashBoardAction";
import { useDispatch, useSelector } from "react-redux";

const AddLocationModal = ({
  saveNewLocation,
  setSelectionModel,
  selectionModel,
  adminLocationList,
}) => {
  const dispatch = useDispatch();
  const { user, roles } = useSelector((state) => state?.Auth);

  const {
    auditor_one_email_error,
    auditor_two_email_error,
    auditor_three_email_error,
    auditor_four_email_error,
    reviewer_email_error,
    approver_email_error,
    auditor_one_email_success,
    auditor_two_email_success,
    auditor_three_email_success,
    auditor_four_email_success,
    reviewer_email_success,
    approver_email_success,
    businessUnit,
    country: countryList,
    o_IdList = {},
  } = useSelector((state) => state?.DashBoard);

  const initError = {
    zoneError: "",
    businessUnitError: "",
    countryError: "",
    locationError: "",
    locationTypeError: "",
    auditorOneError: "",
    auditorTwoError: "",
    auditorThreeError: "",
    auditorFourError: "",
    reviewerError: "",
    approverError: "",
  };
  const initData = {
    zone: "",
    business_unit: "",
    country: "",
    location: "",
    location_type: "",
    auditor_one: "",
    auditor_two: "",
    auditor_three: "",
    auditor_four: "",
    reviewer: "",
    approver: "",
  };
  const [dataError, setDataError] = useState(initError);
  const [data, setData] = useState(initData);
  const [isDataChange, setDataChange] = useState({
    auditor_one: false,
    auditor_two: false,
    auditor_three: false,
    auditor_four: false,
    reviewer: false,
    approver: false,
  });
  const {
    zone,
    business_unit,
    country,
    location,
    location_type,
    auditor_one,
    auditor_two,
    auditor_three,
    auditor_four,
    reviewer,
    approver,
  } = data;
  const {
    zoneError,
    businessUnitError,
    countryError,
    locationError,
    locationTypeError,
    auditorOneError,
    auditorTwoError,
    auditorThreeError,
    auditorFourError,
    reviewerError,
    approverError,
  } = dataError;

  const auditorOneDebounce = useDebounce(data.auditor_one, 2000);
  const auditorTwoDebounce = useDebounce(data.auditor_two, 2000);
  const auditorThreeDebounce = useDebounce(data.auditor_three, 2000);
  const auditorFourDebounce = useDebounce(data.auditor_four, 2000);
  const reviewerDebounce = useDebounce(data.reviewer, 2000);
  const approverDebounce = useDebounce(data.approver, 2000);

  useEffect(() => {
    if (isDataChange.reviewer)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.reviewer,
          checkTypeError: "reviewer_email_error",
          checkTypeSuccess: "reviewer_email_success",
          oidColumnName: "reviewer",
        })
      );
  }, [reviewerDebounce]);
  useEffect(() => {
    if (isDataChange.approver)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.approver,
          checkTypeError: "approver_email_error",
          checkTypeSuccess: "approver_email_success",
          oidColumnName: "approver",
        })
      );
  }, [approverDebounce]);
  useEffect(() => {
    if (isDataChange.auditor_one)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.auditor_one,
          checkTypeError: "auditor_one_email_error",
          oidColumnName: "auditor_one",
          checkTypeSuccess: "auditor_one_email_success",
        })
      );
  }, [auditorOneDebounce]);

  useEffect(() => {
    if (isDataChange.auditor_two)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.auditor_two,
          checkTypeError: "auditor_two_email_error",
          oidColumnName: "auditor_two",
          checkTypeSuccess: "auditor_two_email_success",
        })
      );
  }, [auditorTwoDebounce]);

  useEffect(() => {
    if (isDataChange.auditor_three)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.auditor_three,
          checkTypeError: "auditor_three_email_error",
          oidColumnName: "auditor_three",
          checkTypeSuccess: "auditor_three_email_success",
        })
      );
  }, [auditorThreeDebounce]);

  useEffect(() => {
    if (isDataChange.auditor_four)
      dispatch(
        setAuditorOne({
          oIDParams: o_IdList,
          newLocation: true,
          email: data.auditor_four,
          checkTypeError: "auditor_four_email_error",
          oidColumnName: "auditor_four",
          checkTypeSuccess: "auditor_four_email_success",
        })
      );
  }, [auditorFourDebounce]);

  useEffect(() => {
    setData({
      ...data,
      // country: countryList?.map((d) => d.country),
    });
  }, [businessUnit, countryList]);

  const setErrorHandler = (type, message) => {
    switch (type) {
      case "zone":
        return {
          ...dataError,
          zoneError: message,
        };
      case "business_unit":
        return {
          ...dataError,
          businessUnitError: message,
        };
      case "country":
        return {
          ...dataError,
          countryError: message,
        };
      case "location":
        return {
          ...dataError,
          locationError: message,
        };
      case "location_type":
        return {
          ...dataError,
          locationTypeError: message,
        };
      case "auditor_one":
        return {
          ...dataError,
          auditorOneError: message,
        };
      case "auditor_two":
        return {
          ...dataError,
          auditorTwoError: message,
        };
      case "auditor_three":
        return {
          ...dataError,
          auditorThreeError: message,
        };
      case "auditor_four":
        return {
          ...dataError,
          auditorFourError: message,
        };
      case "reviewer":
        return {
          ...dataError,
          reviewerError: message,
        };
      case "approver":
        return {
          ...dataError,
          approverError: message,
        };
      default:
        return {
          ...dataError,
        };
    }
  };

  const handleCheckAuditor = ({ value, name }) => {
    const auditVal = [
      { name: "auditor_one", value: auditor_one, message: "Auditor One" },
      { name: "auditor_two", value: auditor_two, message: "Auditor Two" },
      {
        name: "auditor_three",
        value: auditor_three,
        message: "Auditor Three",
      },
      { name: "auditor_four", value: auditor_four, message: "Auditor Three" },
    ];
    const filter = auditVal.filter((d) => d.value === value && name !== d.name);

    if (filter.length > 0) {
      setDataError(setErrorHandler(name, "Please use distinct user email"));
    } else {
      setDataError(setErrorHandler(name, ""));
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setData({
      ...data,
      [name]: value,
    });
    setDataChange({
      ...isDataChange,
      [name]: true,
    });

    if (value) {
      switch (name) {
        case "auditor_one":
          handleCheckAuditor({ value, name });
          return;
        case "auditor_two":
          handleCheckAuditor({ value, name });
          return;
        case "auditor_three":
          handleCheckAuditor({ value, name });
          return;
        case "auditor_four":
          handleCheckAuditor({ value, name });
          return;
      }
      setDataError(setErrorHandler(name, ""));
    } else {
      setDataError(setErrorHandler(name, "Please enter some valid input."));
    }
  };

  const isValidForm = () => {
    let error = {
      ...dataError,
    };
    if (!ltrim(zone))
      error = {
        ...error,
        zoneError: "Please enter some valid input.",
      };
    if (!ltrim(business_unit))
      error = {
        ...error,
        businessUnitError: "Please enter some valid input.",
      };
    if (!ltrim(country))
      error = {
        ...error,
        countryError: "Please enter some valid input.",
      };
    if (!ltrim(location))
      error = {
        ...error,
        locationError: "Please enter some valid input.",
      };
    if (!ltrim(location_type))
      error = {
        ...error,
        locationTypeError: "Please enter some valid input.",
      };
    if (!ltrim(auditor_one))
      error = {
        ...error,
        auditorOneError: "Please enter some valid input.",
      };
    // if (!ltrim(auditor_two))
    //   error = {
    //     ...error,
    //     auditorTwoError: "Please enter some valid input.",
    //   };
    // if (!ltrim(auditor_three))
    //   error = {
    //     ...error,
    //     auditorThreeError: "Please enter some valid input.",
    //   };
    // if (!ltrim(auditor_four))
    //   error = {
    //     ...error,
    //     auditorFourError: "Please enter some valid input.",
    //   };
    if (!ltrim(reviewer))
      error = {
        ...error,
        reviewerError: "Please enter some valid input.",
      };
    if (!ltrim(approver))
      error = {
        ...error,
        approverError: "Please enter some valid input.",
      };
    setDataError(error);

    if (
      !!ltrim(zone) &&
      !!ltrim(business_unit) &&
      !!ltrim(country) &&
      !!ltrim(location) &&
      !!ltrim(location_type) &&
      (!!ltrim(auditor_one) ||
        !!ltrim(auditor_two) ||
        !!ltrim(auditor_three) ||
        !!ltrim(auditor_four)) &&
      !!ltrim(reviewer) &&
      !!ltrim(approver) &&
      !auditor_one_email_error &&
      !auditor_two_email_error &&
      !auditor_three_email_error &&
      !auditor_four_email_error &&
      !reviewer_email_error &&
      !approver_email_error
    ) {
      saveNewLocation(data);
      setData(initData);
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "RESET_LOCATION_VALIDATION_INPUT",
      });
    };
  }, []);

  const getDisable = () => {
    return !(
      o_IdList?.auditor_one_oid &&
      o_IdList.reviewer_oid &&
      o_IdList?.approver_oid
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Zone
          </InputLabel>
          <Autocomplete
            disablePortal
            // value={business_unit}
            id="combo-box-demo"
            name="zone"
            onChange={(e, value) => {
              const zoneValue = zoneList.find((d) => d.term === value);
              dispatch(
                getLocationAccess({
                  zone: zoneValue.value,
                })
              );
              handleChange({ target: { value, name: "zone" } });
            }}
            classes={{ root: "autocomplete-input" }}
            options={zoneList.map((d) => d.term)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Enter here.." />
            )}
          />
          {zoneError && (
            <small className="input-error-message">{zoneError}</small>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Business Unit
          </InputLabel>
          <Autocomplete
            disablePortal
            // value={business_unit}
            id="combo-box-demo"
            onChange={(e, value) => {
              handleChange({ target: { value, name: "business_unit" } });
            }}
            classes={{ root: "autocomplete-input" }}
            options={businessUnit?.map((d) => d.business_unit)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Enter here.." />
            )}
          />
          {businessUnitError && (
            <small className="input-error-message">{businessUnitError}</small>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Country
          </InputLabel>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            classes={{ root: "autocomplete-input" }}
            onChange={(e, value) => {
              handleChange({ target: { value, name: "country" } });
            }}
            options={countryList?.map((d) => d.country)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Enter here.." />
            )}
          />
          {countryError && (
            <small className="input-error-message">{countryError}</small>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Location
          </InputLabel>
          <CustomInput
            value={location}
            name="location"
            error={locationError}
            onChange={handleChange}
            placeholder="Enter here.."
            className="mt-0"
          />
          {locationError && (
            <small className="input-error-message">{locationError}</small>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Location Type
          </InputLabel>
          <CustomSelect
            options={[
              { value: "ZX office", lable: "ZX office" },
              { value: "Distribution Center", lable: "Distribution Center" },
              {
                value: "Vertical ops industrial",
                lable: "Vertical ops industrial",
              },
              { value: "ZX Craft brewery", lable: "ZX Craft brewery" },
              {
                value: "Vertical ops agriculture",
                lable: "Vertical ops agriculture",
              },
              // { value: "NA", lable: "NA" },
              { value: "Malting", lable: "Malting" },
              { value: "ZX Warehouse", lable: "ZX Warehouse" },
              { value: "Brewery", lable: "Brewery" },
              { value: "ZX brew pub", lable: "ZX brew pub" },
              { value: "Office", lable: "Office" },
            ]}
            name="location_type"
            onChange={handleChange}
            value={location_type || ""}
            placeholder="Select from the list"
            error={locationTypeError}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Auditor One
          </InputLabel>
          <CustomInput
            name="auditor_one"
            onChange={handleChange}
            value={auditor_one || ""}
            placeholder="Enter here..."
            error={auditorOneError || auditor_one_email_error}
            success={
              !(auditorOneError || auditor_one_email_error) &&
              auditor_one_email_success
            }
          />
          {(auditorOneError || auditor_one_email_error) && (
            <small className="input-error-message">
              {auditorOneError || auditor_one_email_error}
            </small>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Auditor Two
          </InputLabel>
          <CustomInput
            name="auditor_two"
            onChange={handleChange}
            value={auditor_two || ""}
            placeholder="Enter here..."
            error={auditorTwoError || auditor_two_email_error}
            success={
              !(auditorTwoError || auditor_two_email_error) &&
              auditor_two_email_success
            }
          />
          {(auditorTwoError || auditor_two_email_error) && (
            <small className="input-error-message">
              {auditorTwoError || auditor_two_email_error}
            </small>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Auditor Three
          </InputLabel>
          <CustomInput
            name="auditor_three"
            onChange={handleChange}
            value={auditor_three || ""}
            placeholder="Enter here..."
            error={auditorThreeError || auditor_three_email_error}
            success={
              !(auditorThreeError || auditor_three_email_error) &&
              auditor_three_email_success
            }
          />
          {(auditorThreeError || auditor_three_email_error) && (
            <small className="input-error-message">
              {auditorThreeError || auditor_three_email_error}
            </small>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Auditor Four
          </InputLabel>
          <CustomInput
            name="auditor_four"
            onChange={handleChange}
            value={auditor_four || ""}
            placeholder="Enter here..."
            error={auditorFourError || auditor_four_email_error}
            success={
              !(auditorFourError || auditor_four_email_error) &&
              auditor_four_email_success
            }
          />
          {(auditorFourError || auditor_four_email_error) && (
            <small className="input-error-message">
              {auditorFourError || auditor_four_email_error}
            </small>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Reviewer
          </InputLabel>
          <CustomInput
            name="reviewer"
            onChange={handleChange}
            value={reviewer || ""}
            placeholder="Enter here..."
            error={reviewerError || reviewer_email_error}
            success={reviewer_email_success}
          />
          {reviewerError ||
            (reviewer_email_error && (
              <small className="input-error-message">
                {reviewerError || reviewer_email_error}
              </small>
            ))}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className="mb-0" style={{ color: "#000" }}>
            Approver
          </InputLabel>
          <CustomInput
            name="approver"
            onChange={handleChange}
            value={approver || ""}
            placeholder="Enter here..."
            error={approverError || approver_email_error}
            success={approver_email_success}
          />
          {approverError ||
            (approver_email_error && (
              <small className="input-error-message">
                {approverError || approver_email_error}
              </small>
            ))}
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            disabled={getDisable()}
            padding={{ md: "0.5rem 2rem" }}
            style={{ borderRadius: 35, fontWeight: "bold" }}
            width="100%"
            onClick={isValidForm}
          >
            SUBMIT
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};
export default AddLocationModal;
