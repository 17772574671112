import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const AdminDashboardTable = ({ rows, selectionModel, setSelectionModel }) => {
  const { loading, inventoryTotalData } = useSelector(
    (state) => state?.DashBoard
  );
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(50);

  useEffect(() => {
    dispatch({
      type: "SET_INVENTORY_OFFSET",
      payload: page,
    });
  }, [page]);
  console.log("selectionModel", selectionModel);
  return (
    <div className="mt-5 mb-5 pb-5 admin-table">
      <DataGrid
        loading={loading}
        sx={{ width: "100%" }}
        rows={rows}
        rowCount={inventoryTotalData}
        rowsPerPageOptions={[50]}
        autoHeight
        page={page}
        // componentsProps={{
        //   toolbar: { showQuickFilter: true },
        // }}
        components={{
          Toolbar: GridToolbar,
        }}
        classes={{ overlay: "overlay-table" }}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        className="remove-search-boarder"
        checkboxSelection
        hideFooter
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        disableSelectionOnClick
        columns={[
          {
            field: "zone",
            headerName: "Zone",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 120,
          },

          {
            field: "business_unit",
            headerName: "Business Unit",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 120,
          },

          {
            field: "country",
            headerName: "Country",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 120,
          },

          {
            field: "location",
            headerName: "Location",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 120,
          },

          {
            field: "location_type",
            headerName: "Location Type",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 120,
          },

          {
            field: "auditor_one",
            headerName: "Auditor one",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 180,
          },

          {
            field: "auditor_two",
            headerName: "Auditor two",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 100,
          },
          {
            field: "auditor_three",
            headerName: "Auditor three",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 130,
          },
          {
            field: "auditor_four",
            headerName: "Auditor four",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 155,
          },
          {
            field: "reviewer",
            headerName: "Reviewer",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 135,
          },
          {
            field: "approver",
            headerName: "Approver",
            flex: 1,
            cellClassName: "dashboardCell",
            minWidth: 135,
          },
        ]}
      />

      <div className="paginationWrapper">
        <Stack spacing={2}>
          <Pagination
            count={Math.floor(inventoryTotalData / 50)}
            onChange={(event, value) => setPage(value)}
            boundaryCount={2}
          />
        </Stack>
      </div>
    </div>
  );
};
export default AdminDashboardTable;
