import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ErrorAlert({
    handleClose,
    open,
    errorData = {},
}) {
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Error Alert
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Duplicate record found with same Country, Location & Location Type!
                    </Typography>
                </DialogContent>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {`Business Unit=${errorData?.business_unit},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`Country=${errorData?.country},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`Location=${errorData?.location},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`LocationType=${errorData?.location_type},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`Auditor one=${errorData?.auditor_one},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`Reviewer=${errorData?.reviewer},`}
                    </Typography>
                    <Typography gutterBottom>
                        {`Approver=${errorData?.approver},`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        OK
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
