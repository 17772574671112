import React from "react";
import { YELLOW } from "../../../Colors/Colors";
import Button from "@mui/material/Button";
const CustomButton = ({
  backgroundColor = YELLOW,
  textColor = "#000",
  padding = {
    xs: "0.5rem 2rem",
    md: "0rem 2rem",
  },
  variant = "contained",
  width = "auto",
  children,
  ...res
}) => {
  return (
    <Button
      variant={variant}
      sx={{
        backgroundColor,
        "&:hover": {
          backgroundColor,
          color: textColor,
        },
        color: textColor,
        padding,
        width,
      }}
      {...res}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
