import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs({
    handleClose,
    open,
    allQuestionDataCSV,
}) {
    const csvHeader = [
        { label: "Q #", key: "ques_no" },
        { label: "Section", key: "section" },
        { label: "Question", key: "question" },
        { label: "Zone", key: "zone" },
        { label: "Response", key: "response" },
        { label: "Description", key: "description" },
        { label: "Unique ID", key: "uID" },
        { label: "Status", key: "status" },
        { label: "weightage", key: "weightage" },
    ];
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Questionnaire Export
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                     All the questions which are answered including the sub-questions will be exported in this file.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CSVLink
                        data={allQuestionDataCSV}
                        headers={csvHeader}
                        className="btn"
                        filename="questionnaire"
                    >
                        <Button autoFocus onClick={handleClose}>
                            OK
                        </Button>
                    </CSVLink>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
