/* eslint-disable no-useless-computed-key */
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import cs from "classnames";
import "./styles.scss";

const CustomSelect = ({
  size = "small",
  options = [],
  placeholder,
  label,
  widthBorder = true,
  displayEmpty = true,
  error,
  ...res
}) => {
  return (
    <>
      <FormControl
        sx={{ m: 1, minWidth: "100%", maxWidth: 300 }}
        className={cs(
          "customSelectWrapper",
          { ["noBorder"]: !widthBorder },
          { ["error-border"]: error }
        )}
      >
        {!displayEmpty && label && <InputLabel>{label}</InputLabel>}
        <Select
          displayEmpty={displayEmpty}
          inputProps={{ "aria-label": "Without label" }}
          size={size}
          variant="outlined"
          classes={{ root: "testing123" }}
          {...res}
        >
          {placeholder && (
            <MenuItem disabled value="" selected>
              {placeholder}
            </MenuItem>
          )}

          {options.map((o) => (
            <MenuItem value={o.value}>{o.lable}</MenuItem>
          ))}
        </Select>
        {error && <small className="input-error-message">{error}</small>}
      </FormControl>
    </>
  );
};
export default CustomSelect;
