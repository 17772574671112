/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Grid from "@mui/material/Grid";
import { RED, YELLOW } from "../../Colors/Colors";
import StatsCard from "../../components/StatsCard";
import { Container } from "@mui/material";
import ActionLogTable from "../../components/ActionLogTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionLogs,
  getDashBoardData,
  getQuestions,
  getQuestionTabs,
} from "../../redux/DashBoardReducer/DashBoardAction";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getFilterdData,
  getSumValues,
  getVulnerabilityScoreScores,
  getActionLogScores,
  locationFormatter,
} from "../../utils/helperFunctions";
import CustomSelect from "../../components/UI/CustomSelect";
import CustomButton from "../../components/UI/CustomButton";

function ActionLog() {
  // selector
  const {
    countryList = [],
    actionLogs = [],
    loading,
    questions = [],
    listOfZones = [],
    listOfLocations = [],
    selectedZone = "",
    selectedLocation = "",
    dashBoardData = [],
    selectedCountry = "",
    selectedCountryLocation='',
  } = useSelector((state) => state?.DashBoard);
  const { user, roles } = useSelector((state) => state?.Auth);
  const listZones = listOfLocations?.find(
    (data) => data.location === selectedCountryLocation
  );
  const dispatch = useDispatch();

  const [actionLogsTableData, setActionLogsTableData] = useState([]);
  // useEffect(() => {
  //   if (actionLogs?.length > 0) setActionLogsTableData(actionLogs);
  // }, [actionLogs]);

  const [scorList, setScorList] = useState({
    vulnerabilityScore: "0",
    actionLog: "0",
    GITeCAndZIT: "0",
  });

  useEffect(() => {
    if (user?.mail)
      dispatch(
        getActionLogs(
          selectedCountryLocation,
          selectedZone,
          user?.mail?.toLowerCase(),
          roles,
          selectedCountry ||
            dashBoardData?.filter(
              (item) => item?.location === selectedCountryLocation
            )?.[0]?.country
        )
      );
    dispatch(
      getQuestions(
        user?.id,
        user?.mail?.toLowerCase(),
        selectedCountryLocation,
        selectedZone,
        roles,
        selectedCountry ||
          dashBoardData?.filter(
            (item) => item?.location === selectedCountryLocation
          )?.[0]?.country
      )
    );
  }, [user?.mail?.toLowerCase(), selectedZone, selectedCountryLocation]);

  // useEffect(() => {
  //   console.log('selectedZone',selectedZone)
  //   if (!questions.length && user?.mail)
  //     dispatch(
  //       getQuestions(
  //         user?.mail?.toLowerCase(),
  //         selectedCountryLocation,
  //         selectedZone,
  //         roles,
  //         selectedCountry ||
  //           dashBoardData?.filter(
  //             (item) => item?.location === selectedCountryLocation
  //           )?.[0]?.country
  //       )
  //     );
  // }, [user?.mail?.toLowerCase(),selectedCountry,
  //   selectedCountryLocation,
  //   selectedZone,]);

  useEffect(() => {
    if (selectedCountryLocation === "") {
      // setActionLogsTableData(
      //   actionLogs?.filter((item) => item?.zone === listOfZones[0])
      // );

      dispatch({
        type: "SELECT_LOCATION",
        payload: listOfLocations[0]?.location,
      });
      dispatch({
        type: "SELECT_ZONE",
        payload: listOfZones[0],
      });
    } else {
      // setActionLogsTableData(actionLogs?.filter((item) => item?.zone === selectedZone));
    }
  }, [actionLogs, listOfLocations]);

  useEffect(() => {
    if (questions.length) {
      // *****************************************************************************
      const questionSet=questions?.filter(
        (item) =>
          item?.zone === locationFormatter(selectedZone)
      );
      // "Innovation" --section
      const innovationAllSectionData = getFilterdData(
        questionSet,
        "section",
        "Innovation"
      );

      const yesInnovationAllSection = getFilterdData(
        innovationAllSectionData,
        "answer",
        "Yes"
      );
      const innovationWeightage = getSumValues(
        innovationAllSectionData,
        "weightage"
      );
      const yesInnovationWeightage = getSumValues(
        yesInnovationAllSection,
        "weightage"
      );
      const GITeCAndZIT = (
        (yesInnovationWeightage / innovationWeightage) *
        100
      ).toFixed(2);
      const completedAns = getFilterdData(
        actionLogs,
        "log_status",
        "completed"
      );
      console.log(
        "getActionLogScores(questions)",
        completedAns,
        getActionLogScores(actionLogs)
      );

      setScorList({
        ...scorList,
        vulnerabilityScore: getVulnerabilityScoreScores(
          questions.filter((i) =>
            i.zone.includes(locationFormatter(selectedZone))
          )
        ),
        GITeCAndZIT,
        actionLog: getActionLogScores(actionLogs),
      });
    }
  }, [questions, actionLogs]);

  useEffect(() => {
    const findSelectedCountryLocation =
      countryList.find((c) => c.country === selectedCountry) || {};
    console.log(
      "findSelectedCountryLocation",
      findSelectedCountryLocation,
      listOfLocations
    );
    const addZone = findSelectedCountryLocation?.countryLocations?.map((cl) => {
      return {
        location: cl,
        zones: [
          ...new Set(listOfLocations.find((l) => l.location === cl)?.zones),
        ],
      };
    });
    dispatch({
      type: "SET_LIST_OF_LOCATIONS",
      payload: addZone||[],
    });
    const findLocationList = listOfLocations.find(
      (l) => l.location === selectedCountryLocation
    );
    dispatch({
      type: "SET_LIST_OF_ZONES",
      payload: findLocationList?.zones || [],
    });
  }, [questions]);

  const isAuditor = roles === "Auditor";
  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <CircularProgress style={{ color: YELLOW }} />
        </div>
      ) : (
        <div className="pt-5">
          <Grid container sx={{ p: 1 }}>
            {/* {roles === "Auditor" && (
              <StatsCard title="Section Score" value={25} color={YELLOW} />
            )} */}
            <StatsCard
              title="Location Vulnerability Score"
              value={`${scorList.vulnerabilityScore}`}
              color={YELLOW}
            />
            <StatsCard
              title="Action Log Completion"
              value={`${scorList.actionLog}`}
              color={YELLOW}
            />
            {roles === "Auditor" && (
              <StatsCard
                title="GITeC & ZITec Sction Score"
                value={`${scorList.GITeCAndZIT}%`}
                color={YELLOW}
              />
            )}
          </Grid>
          {isAuditor && (
            <Grid container sx={{ p: 1 }}>
              <Grid
                container
                item
                xs={12}
                sm={2}
                md={2}
                sx={{
                  padding: 1,
                  mt: {
                    xs: 1,
                    md: 2,
                  },
                  mb: {
                    xs: 1,
                    md: 2,
                  },
                }}
              >
                <CustomSelect
                  onChange={(e) => {
                    dispatch({
                      type: "SELECT_LOCATION",
                      payload: e.target.value,
                    });
                    dispatch({
                      type: "SET_SELECTED_COUNTRY_LOCATION",
                      payload: e.target.value,
                    });
                    const selectedZonesValue = listOfLocations.find(
                      (i) => i.location === e.target.value
                    )?.zones;
                    dispatch({
                      type: "SELECT_ZONE",
                      payload: selectedZonesValue[0],
                    });
                    dispatch({
                      type: "SET_LIST_OF_ZONES",
                      payload: selectedZonesValue || [],
                    });
                    setActionLogsTableData(
                      actionLogsTableData?.filter(
                        (item) => item?.zone === selectedZonesValue[0]
                      )
                    );
                  }}
                  value={selectedCountryLocation}
                  displayEmpty={false}
                  label="Select Location"
                  placeholder="Select Location"
                  options={listOfLocations?.map((item) => ({
                    value: item.location,
                    lable: item.location,
                  }))}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                md={2}
                sx={{
                  padding: 1,
                  mt: {
                    xs: 1,
                    md: 2,
                  },
                  mb: {
                    xs: 1,
                    md: 2,
                  },
                }}
              >
                <CustomSelect
                  disabled={!selectedCountryLocation}
                  onChange={(e) => {
                    dispatch({
                      type: "SELECT_ZONE",
                      payload: e.target.value,
                    });
                    setActionLogsTableData(
                      actionLogsTableData?.filter(
                        (item) =>
                          item?.zone === locationFormatter(e.target.value)
                      )
                    );
                  }}
                  value={locationFormatter(selectedZone)}
                  displayEmpty={false}
                  label="Select Location Type"
                  placeholder="Select Location Type"
                  options={listZones?.zones?.map((item) => ({
                    value: locationFormatter(item),
                    lable: locationFormatter(item),
                  }))}
                />
              </Grid>
            </Grid>
          )}
          <ActionLogTable actionLogs={actionLogs} />
        </div>
      )}
    </>
  );
}

export default ActionLog;
