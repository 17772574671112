const initialState = {
  open: true,
  message: "",
  status: "",
};

export const UtilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SNACKBAR":
      return {
        ...state,
        open: true,
        message: action.payload.message,
        status: action.payload.status,
      };
    case "CLOSE_SNACKBAR":
      return {
        ...state,
        open: false,
        message: "",
        status: "",
      };
    default:
      return state;
  }
};
