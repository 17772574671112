import React from "react";
import { ReactComponent as Placeholder } from "../../../assets/Images/svg/no-data.svg";
import "./styles.scss";

const NoDataPlaceholder = () => {
  return (
    <div className="text-center w-full my-4">
      <Placeholder />
      <p className="placeholder-text">No Data</p>
    </div>
  );
};
export default NoDataPlaceholder;
