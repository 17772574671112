import React from "react";
import { NavLink } from "react-router-dom";

const AuditorNavbar = () => {
  return (
    <>
      <li className="nav-item">
        <NavLink
          to={"auditor/dashboard"}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          Dashboard
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={"/auditor/questionnaire"}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          Questionnaire
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={"/auditor/action-log"}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          Action Log
        </NavLink>
      </li>
    </>
  );
};

export default AuditorNavbar;
