/* eslint-disable no-useless-computed-key */
/* eslint-disable no-unused-vars */
import React from "react";
import "./styles.scss";
import cs from "classnames";

const CustomInput = ({
  widthBorder = true,
  className,
  error,
  success,
  ...res
}) => {
  return (
    <>
      <input
        className={cs(
          "customInputWrapper",
          { ["widthBorder"]: widthBorder },
          { [className]: className },
          { ["input-error"]: error },
          { ["input-success"]: success }
        )}
        {...res}
      />
    </>
  );
};
export default CustomInput;
