import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { textFormatter } from "../../utils/helperFunctions";

const HistoryTable = ({ historyData }) => {
  return (
    <div style={{ overflow: "auto" }}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Field</TableCell>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell>Update</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyData?.map((row, i) => (
            <TableRow
              key={i}
              sx={{ "td, &:last-child th": { border: "0 !important" } }}
            >
              <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                {textFormatter(row.field)}
              </TableCell>
              <TableCell align="center">{row.user_email}</TableCell>
              <TableCell align="center">{row.user_role}</TableCell>
              <TableCell>{textFormatter(row.updated_field)}</TableCell>
              <TableCell>
                {new Date(row?.timestamp).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {new Date(row?.timestamp).toLocaleTimeString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default HistoryTable;
