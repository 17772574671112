import * as React from "react";

const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    width={20}
    height={20}
    {...props}
  >
    <path d="M64 6.05C49.15 6.05 34.3 11.7 23 23 .4 45.6.4 82.4 23 105a57.94 57.94 0 0 0 82 0c22.6-22.6 22.6-59.4 0-82C93.7 11.7 78.85 6.05 64 6.05zM64 12c13.3 0 26.6 5.1 36.8 15.2 20.2 20.3 20.2 53.3 0 73.6-20.3 20.3-53.3 20.3-73.5 0C7 80.5 7 47.5 27.2 27.2 37.4 17.1 50.7 12 64 12zm0 30c-1.7 0-3 1.3-3 3v16H45c-1.7 0-3 1.3-3 3s1.3 3 3 3h16v16c0 1.7 1.3 3 3 3s3-1.3 3-3V67h16c1.7 0 3-1.3 3-3s-1.3-3-3-3H67V45c0-1.7-1.3-3-3-3z" />
  </svg>
);

export default PlusIcon;
