/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { YELLOW } from "../../Colors/Colors";
import StatsCard from "../../components/StatsCard";
import DashboardTable from "../../components/DashboardTable";
import {
  getDashBoardData,
  getDashboardLocations,
} from "../../redux/DashBoardReducer/DashBoardAction";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomSelect from "../../components/UI/CustomSelect";
import CustomButton from "../../components/UI/CustomButton";
import useWindowSize from "../../hooks/useWindowSize";
import { locationFormatter } from "../../utils/helperFunctions";
import { getProfile } from "../../redux/AuthReducer/AuthAction";

function Dashboard() {
  // dispatch
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  // selector
  const {
    selectedCountry = "",
    selectedCountryLocation = "",
    countryList = [],
    countryLocationList = [],
    listOfLocations = [],
    locationArray = [],
    selectedLocation = "",
    loading,
    dashBoardData = [],
    listOfZones,
    selectedZone = "",
  } = useSelector((state) => state?.DashBoard);

  const { user, roles } = useSelector((state) => state?.Auth);
  const isAuditor = roles === "Auditor";
  const [dashBoardTableData, setDashBoardTableData] = useState([]);

  useEffect(() => {
    dispatch(getProfile());
  }, [])
  useEffect(() => {
    if (
      dashBoardData?.length > 0 &&
      selectedCountry &&
      selectedCountryLocation &&
      selectedZone
    ) {
      setDashBoardTableData(
        dashBoardData?.filter(
          (item) =>
            item?.country === selectedCountry &&
            item.location === selectedCountryLocation &&
            item.location_type === selectedZone
        )
      );
    }
  }, [dashBoardData, selectedCountry, selectedLocation, selectedZone]);

  useEffect(() => {
    if (!selectedCountry && countryList.length) {
      dispatch({
        type: "SET_SELECTED_COUNTRY",
        payload: countryList[0].country || "",
      });
      dispatch({
        type: "SET_SELECTED_COUNTRY_LOCATION",
        payload: countryList[0]?.countryLocations[0] || "",
      });
      const updatedListZone = locationArray.find((i) =>
        i.location.includes(countryList[0]?.countryLocations[0])
      )?.zones;
      dispatch({
        type: "SELECT_ZONE",
        payload: updatedListZone[0],
      });
      // const filterData=listOfLocations.filter(i=>countryList[0]?.countryLocations.includes(i.location))
      // // console.log('listOfLocations',)
      // dispatch({
      //   type: "SET_LIST_OF_LOCATIONS",
      //   payload: filterData,
      // });
    }
  }, [countryList, listOfLocations, locationArray]);

  useEffect(() => {
    if (selectedCountry && selectedCountryLocation)
      dispatch(
        getDashBoardData(
          user?.id,
          user?.mail?.toLowerCase(),
          roles,
          selectedCountry,
          selectedCountryLocation,
          selectedZone
        )
      );
  }, [
    user?.mail?.toLowerCase(),
    selectedCountry,
    selectedCountryLocation,
    selectedZone,
    user?.id,
    roles,
  ]);
  useEffect(() => {
    dispatch(
      getDashboardLocations(
        user?.id,
        user?.mail?.toLowerCase(),
        roles,
        selectedCountry,
        selectedCountryLocation
      )
    );
  }, [user?.mail?.toLowerCase(), user?.id, roles]);
  return (
    <>
      <div className="mt-5 d-flex justify-content-center">
        {loading ? (
          <div className="loading-wrapper">
            <CircularProgress style={{ color: YELLOW }} />
          </div>
        ) : (
          <>
            <div className="w-full">
              <div className="d-flex align-items-center ml-5">
                <Grid
                  container
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  sx={{
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomSelect
                    displayEmpty={false}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_SELECTED_COUNTRY",
                        payload: e.target.value,
                      });
                      const selectedCountryLocation = countryList.find(
                        (i) => i.country === e.target.value
                      )?.countryLocations;
                      // const selectedList = listOfLocations.find(
                      //   (i) => i.country === e.target.value
                      // )?.countryLocations;
                      // dispatch({
                      //   type: "SET_LIST_OF_LOCATIONS",
                      //   payload: listOfLocations || [],
                      // });
                      dispatch({
                        type: "SET_SELECTED_COUNTRY_LOCATION",
                        payload: selectedCountryLocation[0] || "",
                      });

                      const updatedListZone = locationArray.find((i) =>
                        i.location.includes(selectedCountryLocation[0])
                      )?.zones;
                      dispatch({
                        type: "SET_SELECTED_COUNTRY_LOCATION_LIST",
                        payload: selectedCountryLocation,
                      });
                      dispatch({
                        type: "SET_LIST_OF_ZONES",
                        payload: updatedListZone || [],
                      });
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: updatedListZone[0],
                      });
                      setDashBoardTableData(
                        dashBoardData?.filter(
                          (item) =>
                            item?.country === e.target.value &&
                            item.location === selectedCountryLocation[0]
                        )
                      );
                    }}
                    value={selectedCountry}
                    label="Select Country"
                    placeholder="Select Country"
                    options={countryList?.map((item) => ({
                      value: item.country,
                      lable: item.country,
                    }))}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  sx={{
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomSelect
                    displayEmpty={false}
                    disabled={!selectedCountry}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_SELECTED_COUNTRY_LOCATION",
                        payload: e.target.value,
                      });

                      const findSelectedCountryLocation =
                        countryList.find(
                          (c) => c.country === selectedCountry
                        ) || {};
                      const addLocationWithZone =
                        findSelectedCountryLocation.countryLocations.map(
                          (cl) => {
                            return {
                              location: cl,
                              zones: listOfLocations.find(
                                (l) => l.location === cl
                              )?.zones,
                            };
                          }
                        );

                      dispatch({
                        type: "SET_LIST_OF_LOCATIONS",
                        payload: addLocationWithZone,
                      });

                      const updatedListZone = listOfLocations.find((i) =>
                        i.location.includes(e.target.value)
                      ).zones;
                      dispatch({
                        type: "SET_LIST_OF_ZONES",
                        payload: updatedListZone || [],
                      });
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: updatedListZone[0],
                      });
                      setDashBoardTableData(
                        dashBoardData?.filter(
                          (item) =>
                            item?.location === e.target.value &&
                            item.country === selectedCountry
                        )
                      );
                    }}
                    value={selectedCountryLocation}
                    placeholder="Select Location"
                    label="Select Location"
                    options={countryLocationList?.map((item) => ({
                      value: item,
                      lable: item,
                    }))}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  sx={{
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomSelect
                    disabled={!selectedCountryLocation}
                    onChange={(e) => {
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: e.target.value,
                      });
                      setDashBoardTableData(
                        dashBoardData?.filter(
                          (item) =>
                            item?.location_type === e.target.value &&
                            item.location === selectedCountryLocation &&
                            item.country === selectedCountry
                        )
                      );
                    }}
                    value={selectedZone}
                    displayEmpty={false}
                    label="Select Location Type"
                    placeholder="Select Location Type"
                    options={listOfZones?.map((item) => ({
                      value: item,
                      lable: item,
                    }))}
                  />
                </Grid>
                {/* <Grid
                  container
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  sx={{
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomButton
                    style={{
                      padding: width < 600 ? "6px 10px" : "6px 50px",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_COUNTRY",
                        payload: "",
                      });
                      dispatch({
                        type: "SET_SELECTED_COUNTRY_LOCATION",
                        payload: "",
                      });
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: "",
                      });
                      // setDashBoardTableData(dashBoardData);
                    }}
                  >
                    RESET
                  </CustomButton>
                </Grid> */}
              </div>
              <DashboardTable
                tableData={isAuditor ? dashBoardData : dashBoardTableData}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
