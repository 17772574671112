import pureAxios from "axios";
import { loginRequest } from "../../Constants/AuthConfig";
import { axios } from "../../utils/axios";
import { errorNotification } from "../../utils/notificationError";

export const requestProfileData = (instance, accounts) => async (dispatch) => {
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  try {
    const response = await instance.acquireTokenSilent(request);
    localStorage.setItem("idToken", response?.idToken);
    localStorage.setItem("token", response?.accessToken);
    if (!localStorage.getItem("roles")) {
      localStorage.setItem("roles", response?.idTokenClaims?.roles[0]);
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response?.idToken}`;
    axios.defaults.headers.common["Role"] = response?.idTokenClaims?.roles[0];
    dispatch({
      type: "LOGIN_REQUEST_PROFILE",
      payload: {
        idToken: response?.idToken,
        token: response?.accessToken,
        roles: localStorage.getItem("roles")
          ? localStorage.getItem("roles")
          : response?.idTokenClaims?.roles[0],
        userRoles: response?.idTokenClaims?.roles,
      },
    });
  } catch (e) {
    errorNotification(e);
    console.log(e);
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    await pureAxios
      .get("https://graph.microsoft.com/v1.0/me", {
        headers,
      })
      .then((res) => {
        const data = { ...res.data, role: localStorage.getItem("roles") };
        dispatch({
          type: "LOGIN_GET_PROFILE",
          payload: data,
        });
      });
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      localStorage.removeItem("idToken");
      localStorage.removeItem("roles");
      localStorage.removeItem("token");
      dispatch({
        type: "LOGOUT",
      });
      window.location.href = "/";
    }
  }
};

export const userLogout = () => (dispatch) => {
  localStorage.clear();
  localStorage.removeItem("idToken");
  localStorage.removeItem("roles");
  localStorage.removeItem("token");
  dispatch({
    type: "LOGOUT",
  });
};
