import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { YELLOW } from "../../Colors/Colors";
import { Grid } from "@mui/material";
import { dashboardCol } from "../../utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const QuestionnaireResponseTable = ({
  open,
  handleClose,
  selectedRow = {},
  activeSubQuestion = [],
  setActiveSubQuestion,
  activeQuestionResponse = [],
  setActiveQuestionResponse,
  handleSave,
  setSelectedRow,
  handleOpenDialog,
  isModalEditable,
  isEditData,
  isAuditor,
}) => {
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Q # : {selectedRow?.question_id}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container item xs={12} sx={{ width: "100%" }}>
          <DataGrid
            className="remove-search-boarder"
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            autoHeight
            sx={{ width: "100%" }}
            rows={activeSubQuestion?.map((row, index) => {
              const filterData = activeQuestionResponse?.find(
                (item) => item?.id === row?.id
              );
              const gap_description =
                filterData?.status === "Yes" || row?.answer === "Yes"
                  ? filterData?.gap_description || row?.remark
                  : filterData?.gap_description || row?.gap_description;
              return {
                ...row,
                index: `${selectedRow?.index}.${index + 1}`,
                gap_description,
              };
            })}
            components={{
              Toolbar: GridToolbar,
            }}
            columns={[
              {
                field: "index",
                headerName: "S.No.",
                minWidth: 72,
                flex: 1,
              },
              {
                field: "section",
                headerName: "Section",
                flex: 1,
                cellClassName: "QuestionCell",
                minWidth: 180,
              },
              {
                field: "question",
                headerName: "Question",
                flex: 1,
                cellClassName: "QuestionCell",
                minWidth: 560,
              },
              {
                field: "zone",
                headerName: "Zone",
                flex: 1,
                cellClassName: "QuestionCell",
                minWidth: 180,
              },
              {
                field: "weightage",
                headerName: "Weightage",
                flex: 1,
                cellClassName: "QuestionCell",
                minWidth: 150,
              },
              {
                headerName: "Response",
                field: "response",
                flex: 1,
                minWidth: 150,
                renderCell: (row) => {
                  const value =
                    activeQuestionResponse?.find(
                      (item) => item?.id === row?.row?.id
                    )?.status ||
                    row?.row?.answer ||
                    "";
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: "4px",
                      }}
                    >
                      <select
                        style={{
                          width: "100%",
                          padding: "4px",
                          fontWeight: ["Yes", "No"].includes(value)
                            ? "bold"
                            : "normal",
                          background:
                            value === "Yes"
                              ? "#00800080"
                              : value === "No"
                              ? "#fc636380"
                              : "transparent",
                        }}
                        // value={row?.row?.response}
                        value={value}
                        disabled={!isAuditor}
                        onChange={(e) => {
                          const responseValue =
                            "Yes with Remark" === e.target.value
                              ? "Yes"
                              : e.target.value;

                          activeQuestionResponse
                            .map((item) => item?.id)
                            .includes(row?.row?.id)
                            ? setActiveQuestionResponse(
                                activeQuestionResponse.map((item) =>
                                  item?.id === row?.row?.id
                                    ? {
                                        ...item,
                                        status: responseValue,
                                        gap_description: [
                                          "Yes with Remark",
                                          "Yes",
                                        ].includes(e.target.value)
                                          ? null
                                          : item?.gap_description,
                                      }
                                    : item
                                )
                              )
                            : setActiveQuestionResponse([
                                ...activeQuestionResponse,
                                {
                                  id: row?.row?.id,
                                  status: responseValue,
                                  gap_description: null,
                                  weightage: row?.row?.weightage,
                                },
                              ]);
                          if (
                            ["Yes with Remark", "No"].includes(e.target.value)
                          ) {
                            setSelectedRow({
                              id: row?.row?.id,
                              status: responseValue,
                              gap_description: null,
                              weightage: row?.row?.weightage,
                            });
                            if (row?.row?.weightage <= 0) return;
                            handleOpenDialog();
                          }
                        }}
                      >
                        <option value="" disabled>
                          Response
                        </option>
                        {["Yes", "Yes with Remark", "No"].map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <button
                        style={{
                          padding: "4px",
                          marginLeft: "5px",
                          outline: "none",
                          border: "none",
                          backgroundColor:
                            activeQuestionResponse.filter(
                              (item) => item?.id === row?.row?.id
                            ).length === 0
                              ? "grey"
                              : YELLOW,
                          color: "black",
                          borderRadius: "4px",
                          cursor:
                            activeQuestionResponse.filter(
                              (item) => item?.id === row?.row?.id
                            ).length === 0
                              ? "not-allowed"
                              : "pointer",
                        }}
                        disabled={
                          activeQuestionResponse.filter(
                            (item) => item?.id === row?.row?.id
                          ).length === 0
                        }
                        onClick={() =>
                          setActiveQuestionResponse(
                            activeQuestionResponse.filter(
                              (item) => item?.id !== row?.row?.id
                            )
                          )
                        }
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </button>
                    </div>
                  );
                },
              },
              {
                headerName: "Status",
                flex: 1,
                field: "status",
                minWidth: 150,
              },
              {
                field: "gap_description",
                headerName: "Description",
                flex: 1,
                cellClassName: "QuestionCell",
                minWidth: 150,
              },
              {
                headerName: "Unique ID",
                flex: 1,
                // field: "id",
                cellClassName: "QuestionCell",
                minWidth: 150,
                renderCell: (row) => {
                  return <p>{`SAT_${row?.row?.id}`}</p>;
                },
              },
            ]}
            // pageSize={10}
            // rowsPerPageOptions={[10, 50, 100]}
            disableSelectionOnClick
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            isEditData
              ? !activeQuestionResponse.length
              : activeSubQuestion.length !== activeQuestionResponse.length
          }
          onClick={handleSave}
        >
          Save changes
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
export default QuestionnaireResponseTable;
