/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import ApproverActionLog from "../pages/ApproverActionLog/ApproverActionLog";
import AdminDashboard from "../pages/Dashboard/AdminDashboard";
import RequestRole from "../pages/RequestRole";

// Auditor Pages
const AuditorDashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const AuditorQuesstionnaire = lazy(() =>
  import("../pages/Questionnaire/Questionnaire")
);
const AuditorActionLog = lazy(() => import("../pages/ActionLog/ActionLog"));

const Admin = ({ children }) => {
  //TODO: get user role admin then remove this comment
  const { roles } = useSelector((state) => state?.Auth);
  if (roles !== "Admin") {
    return <Navigate to={"/"} />;
  }

  return children;
};
const Auditor = ({ children }) => {
  const { roles } = useSelector((state) => state?.Auth);
  if (roles !== "Auditor") {
    return <Navigate to={"/"} />;
  }

  return children;
};

const Reviewer = ({ children }) => {
  const { roles } = useSelector((state) => state?.Auth);
  if (roles !== "Reviewer") {
    return <Navigate to={"/"} />;
  }

  return children;
};

const Approver = ({ children }) => {
  const { roles } = useSelector((state) => state?.Auth);
  if (roles !== "Approver") {
    return <Navigate to={"/"} />;
  }

  return children;
};

const SuperUser = ({ children }) => {
  const { user } = useSelector((state) => state?.Auth);
  if (user?.role !== "SuperUser") {
    return <Navigate to={"/"} />;
  }

  return children;
};

const LoggedInRoutes = () => {
  const { user, isLoggedIn, roles } = useSelector((state) => state?.Auth);
  const [role, setRole] = useState(null);

  useEffect(() => {
    console.log("ROLE", roles);
    setRole(roles);
  }, [roles]);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        {role === "Auditor" ? (
          <Route path="/" element={<Navigate to={"/auditor/dashboard"} />} />
        ) : role === "Reviewer" ? (
          <Route path="/" element={<Navigate to={"/reviewer/dashboard"} />} />
        ) : role === "Approver" ? (
          <Route path="/" element={<Navigate to={"/approver/dashboard"} />} />
        ) : role === "Admin" ? (
          <Route path="/" element={<Navigate to={"/admin/dashboard"} />} />
        ) : (
          <Route path="/" element={<RequestRole />} />
        )}

        {/* admin Pages */}
        <Route
          path="/admin/dashboard"
          element={
            <Admin>
              <AdminDashboard />
            </Admin>
          }
        />

        {/* Auditor Pages */}
        <Route
          path="/auditor/dashboard"
          element={
            <Auditor>
              <AuditorDashboard />
            </Auditor>
          }
        />

        <Route
          path="/auditor/questionnaire"
          element={
            <Auditor>
              <AuditorQuesstionnaire />
            </Auditor>
          }
        />

        <Route
          path="/auditor/action-log"
          element={
            <Auditor>
              <AuditorActionLog />
            </Auditor>
          }
        />

        <Route
          path="/auditor/request-role"
          element={
            <Auditor>
              <RequestRole />
            </Auditor>
          }
        />

        {/* Reviewer Pages */}
        <Route
          path="/reviewer/dashboard"
          element={
            <Reviewer>
              <AuditorDashboard />
            </Reviewer>
          }
        />

        <Route
          path="/reviewer/questionnaire"
          element={
            <Reviewer>
              <AuditorQuesstionnaire />
            </Reviewer>
          }
        />

        <Route
          path="/reviewer/action-log"
          element={
            <Reviewer>
              <AuditorActionLog />
            </Reviewer>
          }
        />

        <Route
          path="/reviewer/request-role"
          element={
            <Reviewer>
              <RequestRole />
            </Reviewer>
          }
        />

        {/* Approver Pages */}
        <Route
          path="/approver/dashboard"
          element={
            <Approver>
              <AuditorDashboard />
            </Approver>
          }
        />

        <Route
          path="/approver/questionnaire"
          element={
            <Approver>
              <AuditorQuesstionnaire />
            </Approver>
          }
        />

        <Route
          path="/approver/action-log"
          element={
            <Approver>
              <ApproverActionLog />
            </Approver>
          }
        />
        <Route
          path="/approver/request-role"
          element={
            <Approver>
              <RequestRole />
            </Approver>
          }
        />
        <Route exact path={`*`} element={<Navigate to={"/"} />} />
      </Routes>
    </Suspense>
  );
};

export default LoggedInRoutes;
