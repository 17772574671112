const initialState = {
  user: null,
  idToken: null,
  token: null,
  roles: "",
  isLoggedIn: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST_PROFILE":
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
      };
    case "LOGIN_GET_PROFILE":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        idToken: null,
        token: null,
        roles: "",
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
