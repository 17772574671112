import React, { useEffect, useState } from "react";
import "./styles.scss";
import iconImg from "../../assets/Images/oops_img.png";
import CustomSelect from "../../components/UI/CustomSelect";
import { Alert, Autocomplete, Grid, InputLabel, Snackbar, TextareaAutosize, TextField } from "@mui/material";
import CustomButton from "../../components/UI/CustomButton";
import CustomInput from "../../components/UI/CustomInput/CustomInput";
import { getLocationAccess, requestForNewLocation } from "../../redux/DashBoardReducer/DashBoardAction";
import { ltrim, zoneList } from "../../utils/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import ErrorAlert from "../../components/ErrorAlert";

const RequestRole = () => {

  const initError = {
    zoneError: "",
    businessUnitError: "",
    countryError: "",
    locationError: "",
    locationTypeError: "",
    role: '',
  };
  const initData = {
    role: '',
    zone: "",
    select_business_unit: "",
    country: "",
    location: "",
    location_type: "",
    message: "",
  };

  const dispatch = useDispatch();
  const {
    businessUnit,
    country: countryList,
    mailSent,
  } = useSelector((state) => state?.DashBoard);
  const { user, } = useSelector((state) => state?.Auth);

  const [data, setData] = useState(initData);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dataError, setDataError] = useState(initError);
  const {
    role,
    zone,
    select_business_unit,
    country,
    location,
    location_type,
    message,
  } = data;
  const {
    roleError,
    zoneError,
    businessUnitError,
    countryError,
    locationError,
    locationTypeError,
  } = dataError;
  
  useEffect(() => {
    if (mailSent) {
      dispatch({
        type: "SET_MAIL_SENT",
        payload: false,
      });
      setData(initData);
      return setOpenSnackbar(true)
    }
  }, [mailSent])

  const handleChange = ({ target: { value, name } }) => {
    setData({
      ...data,
      [name]: value,
    });

    if (value) {
      setDataError(setErrorHandler(name, ""));
    } else {
      setDataError(setErrorHandler(name, "Please enter some valid input."));
    }
  };

  const setErrorHandler = (type, message) => {
    switch (type) {
      case "role":
        return {
          ...dataError,
          roleError: message,
        };
      case "message":
        return {
          ...dataError,
          messageError: message,
        };
      default:
        return {
          ...dataError,
        };
    }
  };

  const isValidForm = () => {
    let error = {
      ...dataError,
    };
    if (!ltrim(role)) {
      error = {
        ...error,
        roleError: "Please enter some valid input.",
      };
    } else {
      error = {
        ...error,
        roleError: '',
      };
    }
    if (!ltrim(zone)) {
      error = {
        ...error,
        zoneError: "Please enter some valid input.",
      };
    } else {
      error = {
        ...error,
        zoneError: '',
      };
    }
    if (!ltrim(select_business_unit))
      error = {
        ...error,
        businessUnitError: "Please enter some valid input.",
      }; else
      error = {
        ...error,
        businessUnitError: '',
      };
    if (!ltrim(country))
      error = {
        ...error,
        countryError: "Please enter some valid input.",
      };
    else
      error = {
        ...error,
        countryError: '',
      };
    if (!ltrim(location))
      error = {
        ...error,
        locationError: "Please enter some valid input.",
      }; else
      error = {
        ...error,
        locationError: '',
      };
    if (!ltrim(location_type))
      error = {
        ...error,
        locationTypeError: "Please enter some valid input.",
      };
    else
      error = {
        ...error,
        locationTypeError: '',
      };
    setDataError(error);

    if (
      !!ltrim(role) &&
      !!ltrim(zone) &&
      !!ltrim(select_business_unit) &&
      !!ltrim(country) &&
      !!ltrim(location) &&
      !!ltrim(location_type)
    ) {
      // api call
      const params = { role, zone, businessUnit: select_business_unit, country, location, locationType: location_type, message, userMail: user?.mail }
      dispatch(requestForNewLocation(params))
    }
  };
  
 
  return (
    <div className="p-4">
      
      {openSnackbar &&
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => {
            setOpenSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenSnackbar(false);
            }}
            severity='success'
            sx={{ width: "100%" }}
          >
           Location allocation mail is Sent to admin.
          </Alert>
        </Snackbar>
      }
      <Grid container className="d-block mt-8 d-md-flex align-items-center">
        <Grid item xs={12} md={8}  className="top-title-wrapper">
          {/* <h3>
            Oops! You do not have any role assigned to perform any action on
            this application.
          </h3> */}
          <h3>
            Kindly fill the form to submit a request to the admin for the role
            assignment.
          </h3>
          <img src={iconImg} style={{ width: "60%" ,marginLeft:'20%'}} />
        </Grid>

        <Grid item xs={11} md={2} className="form-side">
          <div className="form-wrapper">
            <h4 className="text-center mb-5">Request Role</h4>
            <InputLabel sm={{ mb: 1 }} style={{ color: "#000" }}>
              Select Role
            </InputLabel>
            <CustomSelect
              options={[
                { value: "Reviewer", lable: "Reviewer" },
                { value: "Auditor", lable: "Auditor" },
                { value: "Approver", lable: "Approver" },
                // { value: "Admin", lable: "Admin" },
              ]}
              name="role"
              onChange={handleChange}
              value={data.role || ""}
              placeholder="SELECT"
              error={dataError.roleError}
            />
            <Grid item xs={12} md={12}>
              <InputLabel className="mb-0" style={{ color: "#000", marginTop: 10 }}>
                Select Zone
              </InputLabel>
              <Autocomplete
                disablePortal
                // value={select_business_unit}
                id="combo-box-demo"
                name="zone"
                onChange={(e, value) => {
                  const zoneValue = zoneList.find((d) => d.term === value);
                  dispatch(
                    getLocationAccess({
                      zone: zoneValue.value,
                    })
                  );
                  handleChange({ target: { value, name: "zone" } });
                }}
                classes={{ root: "autocomplete-input" }}
                options={zoneList.map((d) => d.term)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Enter here.." />
                )}
              />
              {zoneError && (
                <small className="input-error-message">{zoneError}</small>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel className="mb-0" style={{ color: "#000", marginTop: 10 }}>
                Select Business unit
              </InputLabel>
              <Autocomplete
                disablePortal
                // value={select_business_unit}
                id="combo-box-demo"
                onChange={(e, value) => {
                  handleChange({ target: { value, name: "select_business_unit" } });
                }}
                classes={{ root: "autocomplete-input" }}
                options={businessUnit?.map((d) => d.business_unit)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Enter here.." />
                )}
              />
              {businessUnitError && (
                <small className="input-error-message">{businessUnitError}</small>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel className="mb-0" style={{ color: "#000", marginTop: 10 }}>
                Country
              </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                classes={{ root: "autocomplete-input" }}
                onChange={(e, value) => {
                  handleChange({ target: { value, name: "country" } });
                }}
                options={countryList?.map((d) => d.country)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Enter here.." />
                )}
              />
              {countryError && (
                <small className="input-error-message">{countryError}</small>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel className="mb-0" style={{ color: "#000", marginTop: 10 }}>
                Location
              </InputLabel>
              <CustomInput
                value={location}
                name="location"
                error={locationError}
                onChange={handleChange}
                placeholder="Enter here.."
                className="mt-0"
              />
              {locationError && (
                <small className="input-error-message">{locationError}</small>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel className="mb-0" style={{ color: "#000", marginTop: 10 }}>
                Location Type
              </InputLabel>
              <CustomSelect
                options={[
                  { value: "ZX office", lable: "ZX office" },
                  { value: "Distribution Center", lable: "Distribution Center" },
                  {
                    value: "Vertical ops industrial",
                    lable: "Vertical ops industrial",
                  },
                  { value: "ZX Craft brewery", lable: "ZX Craft brewery" },
                  {
                    value: "Vertical ops agriculture",
                    lable: "Vertical ops agriculture",
                  },
                  // { value: "NA", lable: "NA" },
                  { value: "Malting", lable: "Malting" },
                  { value: "ZX Warehouse", lable: "ZX Warehouse" },
                  { value: "Brewery", lable: "Brewery" },
                  { value: "ZX brew pub", lable: "ZX brew pub" },
                  { value: "Office", lable: "Office" },
                ]}
                name="location_type"
                onChange={handleChange}
                value={location_type || ""}
                placeholder="Select from the list"
                error={locationTypeError}
              />
            </Grid>
            <InputLabel sm={{ mb: 2 }} style={{ color: "#000", marginTop: 10 }}>
              Message for Admin
            </InputLabel>
            <TextareaAutosize
              maxRows={6}
              minRows={6}
              aria-label="maximum height"
              placeholder="Enter text here..."
              style={{ width: "100%" }}
              name='message'
              onChange={handleChange}
            />
            <div className="mt-7 mb-5">
              <CustomButton
                className="w-full p-1"
                style={{ borderRadius: 35, fontWeight: "bold" }}
                onClick={isValidForm}
              >
                SUBMIT
              </CustomButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default RequestRole;
