import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import { YELLOW } from "../../Colors/Colors";

function QuestionnaireDialog({
  openDialog,
  handleCloseDialog,
  selectedRow,
  addDescription,
  handleForceCloseDialog,
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{}}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid black",
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">Q # : {selectedRow?.id}</Typography>
        <Button
          sx={{ fontSize: "1rem", color: "black" }}
          onClick={()=>handleForceCloseDialog(selectedRow)}
        >
          <i class="fa-solid fa-xmark"></i>
        </Button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body2" sx={{ color: "black" }}>
            For {selectedRow?.status} Answer please provide the
            {selectedRow?.status === 'Yes' ? " Remark." : " GAP Description."}
          </Typography>
          <TextareaAutosize
            minRows={8}
            placeholder="Enter here . . ."
            value={selectedRow?.gap_description}
            style={{
              width: "100%",
              marginTop: "12px",
              padding: "6px",
              outline: "none",
              border: "1px solid grey",
              resize: "none",
            }}
            onChange={(e) => {
              addDescription(selectedRow?.id, e.target.value);
            }}
          />
          {selectedRow?.status === 'No' && !selectedRow?.gap_description && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              *must be filled.
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            display: "block",
            margin: "auto",
            backgroundColor: YELLOW,
            color: "black",
            pl: 12,
            pr: 12,
          }}
          onClick={()=>handleCloseDialog(selectedRow)}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionnaireDialog;
