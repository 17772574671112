import React, { useEffect, useState } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';

import { app_name } from '../../utils/config'

import { Button, Toast, Row, Col, Container, ToastContainer } from 'react-bootstrap';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    // setShowReload(true);
    console.log("Hello buddy how are you")
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    const updateAvailable = localStorage.getItem("update")
    setShowReload(updateAvailable);
  }, []);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    localStorage.removeItem("update")
    setShowReload(false);
    clearCacheData ()
    window.location.reload(true);
  };
  

  return (
    <>
      {showReload &&
        <ToastContainer className="p-3 app-update-popup" style={{ zIndex: '9999' }}>
          <Toast show={showReload} >
            <Toast.Header closeButton={false}>
              <strong className="me-auto orange-text">{app_name}</strong>
            </Toast.Header>
            <Toast.Body>
              <Container>
                <Row>
                  <Col>{`A new version is available!`}</Col>
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                  <Col xs={12}>
                    <Button
                      className="btn-block btn-warning"
                      style={{ borderRadius: '40px' }}
                      onClick={reloadPage}
                    >
                      {`UPDATE`}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      }
    </>
  );
};

export default ServiceWorkerWrapper;
