/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/Images/abi_logo.png";
import Applogo from "../../assets/Images/SatLogo.png";
import PDF from "../../assets/SOP-SAT Security.pdf";

import Styles from "./Navbar.module.scss";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../Constants/AuthConfig";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/AuthReducer/AuthAction";
import AuditorNavbar from "./AuditorNavbar";
import ReviewerNavbar from "./ReviewerNavbar";
import ApproverNavbar from "./ApproverNavbar";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { errorNotification } from "../../utils/notificationError";

const Navbar = () => {
  // dispatch
  const dispatch = useDispatch();
  // selector
  const {
    user,
    isLoggedIn,
    roles,
    userRoles = [],
  } = useSelector((state) => state?.Auth);
  // theme
  const isAdmin = roles === "Admin";
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = "";
  const ref = useRef();
  const refLink = useRef();
  const location = useLocation();
  const login = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
      errorNotification(e);
    });
  };
  const [isHeaderOpen, setisHeaderOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isDropDownUserOpen, setIsDropDownUserOpen] = useState(false);

  useOnClickOutside(ref, () => {
    setIsDropDownOpen(false);
  });

  useOnClickOutside(refLink, () => {
    setIsDropDownUserOpen(false);
  });

  const togglingHeader = () => {
    setisHeaderOpen(!isHeaderOpen);
  };

  const togglingDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };
  const togglingDropDownUser = () => {
    setIsDropDownUserOpen(!isDropDownUserOpen);
  };

  function handleProfile() {
    history.push("/myprofile");
  }

  const logOut = () => {
    localStorage.clear();
    localStorage.removeItem("idToken");
    localStorage.removeItem("roles");
    localStorage.removeItem("token");
    instance.logoutRedirect().then(
      () => {
        localStorage.clear();
        localStorage.removeItem("idToken");
        localStorage.removeItem("roles");
        localStorage.removeItem("token");
        window.location.href = "/"; // replace this with something better
      },
      (error) => {
        // console.log(error);
      }
    );
  };

  // console.log(process.env.REACT_APP_STAGE, "process.env.REACT_APP_STAGE");

  const handleRoleSelect = (role) => () => {
    localStorage.setItem("roles", role);
    dispatch({
      type: "LOGIN_REQUEST_PROFILE",
      payload: {
        roles: role,

        user: {
          ...user,

          role,
        },
      },
    });
    window.location.href = "/";
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{ background: "#000", position: "relative", padding: "0.5rem" }}
      >
        <Toolbar>
          <div style={{ display: "flex", height: "100%" }}>
            <img
              src={Applogo}
              alt="logos"
              style={{
                width: "65px",
                resize: "auto",
              }}
            />
            <div style={{ backgroundColor: "#d5cece85", width: 2 }} />
            <img
              src={logo}
              alt="logos"
              style={{
                width: "200px",
                // height: "fit-content",
                alignSelf: "center",
                marginLeft: 10,
              }}
            />
          </div>
          {isMatch ? (
            <>
              <DrawerComp
                isLoggedIn={isLoggedIn}
                logOut={logOut}
                login={login}
                user={user}
                roles={roles}
              />
            </>
          ) : (
            <>
              {roles ? (
                <div className={Styles.Links}>
                  <div
                    className="d-flex order-lg-2 ml-auto text-left"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    {/* {console.log(
                    "process.env.REACT_APP_STAGE === 'prod'",
                    typeof process.env.REACT_APP_STAGE,
                    typeof "prod"
                  )} */}
                    <span
                      className="golden-text"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {userRoles?.length >= 2 && (
                        <div className="dropdown">
                          <a
                            className="nav-link pr-0 leading-none mr-3"
                            onClick={togglingDropDownUser}
                          >
                            <span
                              style={{ borderRadius: 10 }}
                              className=" d-none d-lg-block border p-3 border-warning"
                            >
                              <span className="mr-1">Select role</span>
                              {isDropDownUserOpen ? (
                                <i className="fa fa-angle-up"></i>
                              ) : (
                                <i className="fa fa-angle-down"></i>
                              )}
                            </span>
                          </a>
                          {isDropDownUserOpen && (
                            <div
                              ref={refLink}
                              className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                              style={{ cursor: "pointer" }}
                            >
                              {userRoles.map((r) => (
                                <span
                                  className="px-3 py-1 d-block"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleRoleSelect(r)}
                                >
                                  {r}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </span>
                    <span
                      className="golden-text"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {process.env.REACT_APP_STAGE === "prod" ? (
                        <strong>{`LIVE`}</strong>
                      ) : (
                        <strong>{`BETA`}</strong>
                      )}
                    </span>

                    <div className="dropdown">
                      <a
                        className="nav-link pr-0 leading-none"
                        onClick={togglingDropDown}
                      >
                        {/* <img
                    className="avatar"
                    src={!"profilePhoto" ? URL.createObjectURL('profilePhoto') : logo}
                    alt=""
                  /> */}
                        {/* <img className="avatar" src={defaultProfilePhoto} alt="" /> */}
                        <span className="ml-2 d-none d-lg-block">
                          <span className="golden-text">
                            <strong>
                              {accounts.length > 0 ? accounts[0].name : ""}
                            </strong>
                          </span>
                          <span>
                            &emsp;&emsp;
                            {isDropDownOpen ? (
                              <i className="fa fa-angle-up"></i>
                            ) : (
                              <i className="fa fa-angle-down"></i>
                            )}
                          </span>
                          <small className="text-muted d-block mt-1">
                            {accounts.length > 0 ? accounts[0].username : ""}
                          </small>

                          {isLoggedIn && (
                            <p className="mt-1">
                              {/* {user?.givenName}
                    <br /> */}
                              <span>{roles}</span>
                            </p>
                          )}
                        </span>
                      </a>
                      {isDropDownOpen && (
                        <div
                          ref={ref}
                          className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <a className="dropdown-item text-left" onClick={handleProfile}>
                      My Profile
                    </a> */}
                          {/* <a
                          className="dropdown-item text-left m-0"
                        // href="mailto:DL-COEAutomationRM@AnheuserBuschInBev.onmicrosoft.com"
                        >
                          Support
                        </a> */}
                          <a
                            className="dropdown-item text-left m-0"
                            onClick={logOut}
                          >
                            Log out
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <a
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                    className={`header-toggler d-lg-none ml-3 ml-lg-0 ${
                      isHeaderOpen ? "" : "collapsed"
                    }`}
                    onClick={togglingHeader}
                  >
                    <span className="header-toggler-icon"></span>
                  </a>
                </div>
              ) : (
                <div className={Styles.Links}>
                  <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <strong>
                      {accounts.length > 0 ? accounts[0].name : ""}
                    </strong>
                  </span>

                  <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <strong>
                      <a
                        className="cursor-pointer text-left m-0"
                        onClick={logOut}
                      >
                        Log out
                      </a>
                    </strong>
                  </span>
                </div>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {!isAdmin && roles && (
        <div
          className={`header collapse d-lg-flex p-0 ${
            isHeaderOpen ? "show" : ""
          }`}
          id="headerMenuCollapse"
          style={{
            background: "linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)",
          }}
        >
          <div className="px-6 w-100">
            <div className="row align-items-center">
              <div className="col-lg order-lg-first">
                <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                  {roles === "Auditor" && <AuditorNavbar />}
                  {roles === "Reviewer" && <ReviewerNavbar />}
                  {roles === "Approver" && <ApproverNavbar />}
                </ul>
              </div>
              <div className="mr-6">
                <NavLink
                  to={`${roles?.toLowerCase()}/request-role`}
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                    border: "solid 1px",
                    padding: 10,
                    borderRadius: 10,
                    boxShadow:
                      "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                  }}
                  // className={({ isActive }) => (isActive ? "active" : "inactive")}
                >
                  Request New Role
                </NavLink>

                <a
                  href={
                    "https://anheuserbuschinbev.sharepoint.com/teams/global-CA/GlobalSecurity/Security%20Standards/Forms/AllItems.aspx?RootFolder=%2Fteams%2Fglobal%2DCA%2FGlobalSecurity%2FSecurity%20Standards%2FSecurity%20Standards%20%2D%20SOP&FolderCTID=0x012000532A0F50F65C9F47B76FA6E937D6FF95"
                  }
                  target={"_blank"}
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                    border: "solid 1px",
                    padding: 10,
                    borderRadius: 10,
                    boxShadow:
                      "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                  }}
                >
                  Global Security Sharepoint
                </a>
                <a
                  href={
                    "https://anheuserbuschinbev.sharepoint.com/teams/global-CA/GlobalSecurity/Security%20Standards/Forms/AllItems.aspx?id=%2Fteams%2Fglobal%2DCA%2FGlobalSecurity%2FSecurity%20Standards%2FSecurity%20Standards%20%2D%20SOP%2FSOP%20for%20SAT%20Tool%2Epdf&parent=%2Fteams%2Fglobal%2DCA%2FGlobalSecurity%2FSecurity%20Standards%2FSecurity%20Standards%20%2D%20SOP&p=true&ct=1661408953282&or=Outlook-Body&cid=62B50307-DEC2-4790-A03F-5579B5B1CAC7&ga=1"
                  }
                  target={"_blank"}
                  style={{
                    cursor: "pointer",
                    border: "solid 1px",
                    padding: 10,
                    borderRadius: 10,
                    boxShadow:
                      "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                  }}
                >
                  Open SOP For Tool Guideline
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Navbar;

const DrawerComp = ({ isLoggedIn, logOut, login, user, roles }) => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { userRoles = [] } = useSelector((state) => state?.Auth);
  const [isDropDownUserOpen, setIsDropDownUserOpen] = useState(false);
  const refLink = useRef();

  useOnClickOutside(refLink, () => {
    setIsDropDownUserOpen(false);
  });

  const togglingDropDownUser = () => {
    setIsDropDownUserOpen(!isDropDownUserOpen);
  };

  const handleRoleSelect = (role) => () => {
    localStorage.setItem("roles", role);
    dispatch({
      type: "LOGIN_REQUEST_PROFILE",
      payload: {
        roles: role,
      },
    });
    window.location.href = "/";
  };
  // theme
  return (
    <React.Fragment>
      <Drawer
        PaperProps={{
          sx: {
            background:
              "linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)",
          },
        }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {isLoggedIn && roles && (
            <p
              style={{
                margin: "auto",
                display: "block",
                padding: "1rem",
                fontWeight: 600,
                // textAlign: "center",
              }}
            >
              {user?.givenName}
              <br />
              <span style={{ fontSize: "12px", fontWeight: 400 }}>{roles}</span>
            </p>
          )}
          {!roles && (
            <span
              style={{
                margin: "auto",
                display: "block",
                padding: "1rem",
                fontWeight: 600,
                // textAlign: "center",
              }}
            >
              {user?.givenName}
            </span>
          )}
          {roles && (
            <>
              {roles === "Auditor" && (
                <AuditorDrawerLinks setOpenDrawer={setOpenDrawer} />
              )}
              {roles === "Reviewer" && (
                <ReviewerDrawerLinks setOpenDrawer={setOpenDrawer} />
              )}
              {roles === "Approver" && (
                <ApproverDrawerLinks setOpenDrawer={setOpenDrawer} />
              )}
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <a
                      className="mobileInactive"
                      href={
                        "https://anheuserbuschinbev.sharepoint.com/teams/global-CA/GlobalSecurity/Security%20Standards/Forms/AllItems.aspx?id=%2Fteams%2Fglobal%2DCA%2FGlobalSecurity%2FSecurity%20Standards%2FSecurity%20Standards%20%2D%20SOP%2FSOP%20for%20SAT%20Tool%2Epdf&parent=%2Fteams%2Fglobal%2DCA%2FGlobalSecurity%2FSecurity%20Standards%2FSecurity%20Standards%20%2D%20SOP&p=true&ct=1661408953282&or=Outlook-Body&cid=62B50307-DEC2-4790-A03F-5579B5B1CAC7&ga=1"
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {"Open SOP For Tool Guideline"}
                    </a>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </>
          )}
        </List>

        <span>
          {userRoles?.length >= 2 && (
            <div className="dropdown">
              <a
                className="nav-link pr-0 leading-none mr-3"
                onClick={togglingDropDownUser}
              >
                <span style={{ borderRadius: 10 }} className="border p-3">
                  <span className="mr-1">Select role</span>
                  {isDropDownUserOpen ? (
                    <i className="fa fa-angle-up"></i>
                  ) : (
                    <i className="fa fa-angle-down"></i>
                  )}
                </span>
              </a>
              {isDropDownUserOpen && (
                <div
                  ref={refLink}
                  className="dropdown-menu dropdown-menu-left dropdown-menu-arrow show"
                  style={{ cursor: "pointer" }}
                >
                  {userRoles.map((r) => (
                    <span
                      className="px-3 py-1 d-block"
                      style={{ cursor: "pointer" }}
                      onClick={handleRoleSelect(r)}
                    >
                      {r}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
        </span>
        {isLoggedIn ? (
          <Button
            onClick={() => logOut()}
            variant="contained"
            sx={{ margin: "auto" }}
          >
            Log Out
          </Button>
        ) : (
          <Button
            onClick={() => login()}
            variant="contained"
            sx={{ margin: "auto" }}
          >
            Login
          </Button>
        )}
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

function AuditorDrawerLinks({ setOpenDrawer }) {
  return (
    <>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/auditor/dashboard"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Dashboard
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/auditor/questionnaire"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Questionnaire
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/auditor/action-log"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Action Log
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
    </>
  );
}

function ReviewerDrawerLinks({ setOpenDrawer }) {
  return (
    <>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/reviewer/dashboard"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Dashboard
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/reviewer/questionnaire"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Questionnaire
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/reviewer/action-log"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Action Log
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
    </>
  );
}
function ApproverDrawerLinks({ setOpenDrawer }) {
  return (
    <>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/approver/dashboard"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Dashboard
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/approver/questionnaire"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Questionnaire
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ListItemText>
            <NavLink
              to={"/approver/action-log"}
              className={({ isActive }) =>
                isActive ? "mobileActive" : "mobileInactive"
              }
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Action Log
            </NavLink>
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
    </>
  );
}
