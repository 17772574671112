/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import pureAxios from "axios";
import { axios } from "./utils/axios";
import Navigation from "./components/Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./Constants/AuthConfig";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, requestProfileData } from "./redux/AuthReducer/AuthAction";
import Footer from "./components/Footer/Footer";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import LoggedOutRoutes from "./routes/LoggedOutRoutes";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper/ServiceWorkerWrapper";
import "./assets/styles/App.css";
import Navbar from "./components/Navbar/Navbar";

const App = () => {
  // dispatch
  const dispatch = useDispatch();
  // selector
  const { isLoggedIn } = useSelector((state) => state?.Auth);

  const isAuthenticated = useIsAuthenticated();
  // const [loggedIn, setLoggedIn] = useState(!localStorage.getItem("token"));

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
    }

    if (accounts[0]) {
      // requestProfileData();
      dispatch(requestProfileData(instance, accounts));
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getProfile());
    }
    if (!accounts) {
      instance.logout();
      localStorage.removeItem('idToken');
      localStorage.removeItem('roles');
      localStorage.removeItem('token');
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  return (
    <>
      {isAuthenticated && <Navbar />}
      <ServiceWorkerWrapper />
      <section className="appBody">
        {!isAuthenticated ? <LoggedOutRoutes /> : <LoggedInRoutes />}
      </section>
      {/* {isLoggedIn && <Footer />} */}
    </>
  );
};

export default App;
