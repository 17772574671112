export const converToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
};

export const getSumValues = (array, type) => {
  let val = 0;
  array.forEach((s) => {
    if (s[type]) val += s[type];
  });
  return val;
};

export const getFilterdData = (array, key, condition) => {
  return array.filter((s) => s[key] === condition);
};

export const getActionLogScores = (ansArray = []) => {
  const completedAns = getFilterdData(ansArray, "log_status", "completed");
  const allQuestion = ansArray.filter((i) => i.log_status);
  const allQuestionWeightage = getSumValues(ansArray, "weightage");
  const completedAnsWeightage = getSumValues(completedAns, "weightage");
  const actionLog = (
    (completedAnsWeightage / allQuestionWeightage) *
    100
  ).toFixed(2);
  return Number.isNaN(+actionLog) ? "0.00%" : `${actionLog}%`;
};
export const getVulnerabilityScoreScores = (
  ansArray = [],
  question,
  dashboard = false,
  perimeterIndex
) => {
  const quesList = dashboard ? question : ansArray;
  const yesQes = quesList.filter(
    (i) => i.answer === "Yes"
  );
  const perimeterAnsArray = quesList?.filter(q => q?.section === 'Perimeter') || []
  const filterAnsArray = yesQes?.filter(q => q?.section !== 'Perimeter') || []
  let ansDataArray = []
  
  if (perimeterAnsArray[0]?.answer === 'Yes') {
    if (perimeterAnsArray[1]?.answer === 'Yes') {
      const filteredData = perimeterAnsArray?.slice(0, 2) || []
      ansDataArray = [...filterAnsArray, ...filteredData]
    } else if (!perimeterAnsArray[1]?.answer) {
      ansDataArray = yesQes
    } else if (perimeterAnsArray[1]?.answer === 'No') {
      ansDataArray = yesQes
    }
  } else if (perimeterAnsArray[0]?.answer === 'No') {
    const updatedData = yesQes?.filter(i => i.id !== perimeterAnsArray[1]?.id)
    ansDataArray = !perimeterIndex ? updatedData : yesQes
  } else {
    ansDataArray = yesQes
  }

  // (i) => i.answer === "Yes" && i.is_parent_question !== "Yes"
  const allQes = quesList.filter(
    (i) =>
      i.is_sub_question === "No" || (i.is_sub_question === "Yes" && i.answer)
  );
  let dataArray = []
  const perimeterArray = quesList.filter(q => q.section === 'Perimeter') || []
  const filterArray = quesList.filter(q => q.section !== 'Perimeter') || []

  if (!perimeterArray[0]?.answer) {
    dataArray = [...filterArray, perimeterArray[0]]
  }
  if (perimeterArray[0]?.answer === 'Yes') {
    if (perimeterArray[1]?.answer === 'Yes') {
      const filteredData = perimeterArray?.slice(0, 2) || []
      dataArray = [...filterArray, ...filteredData]
    } else if (!perimeterArray[1]?.answer) {
      dataArray = [...filterArray, perimeterArray[0], perimeterArray[1]]
    } else if (perimeterArray[1]?.answer === 'No') {
      dataArray = [...filterArray, ...perimeterArray]
    }
  } else if (perimeterArray[0]?.answer === 'No') {
    if (!perimeterIndex) {
      perimeterArray.splice(1, 1)
    }
    dataArray = [...filterArray, ...perimeterArray]
  }
  let questionArr = []
  dataArray.forEach(element => {
    if (element.weightage === 0 && element.answer === 'No') {
      const quesArray = element.if_yes_enable?.split('/') || []
      questionArr.push(...quesArray)
    }
  });

  const dataRowValue = dataArray.filter(i => !questionArr.includes(i.ques_no))
  const scoreOfAllQuestionAllSection = getSumValues(dataRowValue, "weightage");
  const yesAnswerdScoreAllSection = getSumValues(ansDataArray, "weightage");

  console.log('yesAnswerdScoreOneSection===', yesAnswerdScoreAllSection, scoreOfAllQuestionAllSection)
  const vulnerabilityScore = (
    (yesAnswerdScoreAllSection / scoreOfAllQuestionAllSection) *
    100
  ).toFixed(2);

  return Number.isNaN(+vulnerabilityScore) ? "0.00%" : `${vulnerabilityScore}%`;
};

export const textFormatter = (status) => {
  switch (status) {
    case "completed":
      return "Completed";
    case "inProgress":
      return "In Progress";
    case "delayed":
      return "Delayed";
    case "not_Started_yet":
      return "Not Started Yet";
    case "status":
      return "Status";
    case "endDate":
      return "End Date";
    case "startDate":
      return "Start Date";
    case "comment":
      return "Comment";
    case "budget":
      return "Budget";
    case "rating":
      return "Rating";
    case "category":
      return "Category";
    case "actions":
      return "Action";
    default:
      return status;
  }
};

export const greyOutTableCellReviewer = (status) => {
  if (status === "Accepted by approver" || status === "Accepted by reviewer") {
    return true;
  } else {
    return false;
  }
};

export const greyOutTableCellApprover = (status) => {
  if (status === "Accepted by approver") {
    return true;
  } else {
    return false;
  }
};

export const dashboardCol = (isAuditor) => {
  const updatedCols = [
    {
      field: "index",
      headerName: "Sl. No.",
      minWidth: 72,
      flex: 1,
    },
  ];
  if (!isAuditor)
    return [
      ...updatedCols,
      {
        field: "id",
        headerName: "Q ID",
        minWidth: 72,
        flex: 1,
      },
    ];
  return updatedCols;
};

export const locationFormatter = (loc) => {
  let lt = loc;
  let location_type = "";
  if (lt === "Distribution Center") {
    location_type = "Distribution Centre";
  } else if (lt === "Office") {
    location_type = "Office";
  } else if (
    lt === "ZX office" ||
    lt === "ZX Craft brewery" ||
    lt === "ZX brew pub" ||
    lt === "ZX Warehouse"
  ) {
    location_type = "Craft  ZX BrewPub Breweries";
  } else if (lt === "Vertical ops industrial") {
    location_type = "Vertical Operations  Industrial";
  } else if (lt === "Vertical ops agriculture") {
    location_type = "Vertical Operations  Agricultural";
  } else {
    location_type = lt;
  }
  return location_type;
};

export function ltrim(str = '') {
  if (!str) return str;
  return str?.replace(/^\s+/g, "");
}

export const zoneList = [
  { term: "South America", value: "South America" },
  { term: "Middle America", value: "Middle America" },
  { term: "North America", value: "North America" },
  { term: "Africa", value: "Africa" },
  { term: "Europe", value: "Europe" },
  { term: "APAC", value: "APAC" },
];
