/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import CustomButton from "../UI/CustomButton";
import "./styles.scss";
import EditIcon from "@mui/icons-material/Edit";
import { SILVER, YELLOW } from "../../Colors/Colors";
import CustomDialogs from "../UI/CustomDialogs";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CustomSelect from "../UI/CustomSelect";
import IconButton from "@mui/material/IconButton";
import { InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CustomInput from "../UI/CustomInput/CustomInput";
import RowActions from "./RowActions";
import { useDispatch, useSelector } from "react-redux";
import NoDataPlaceholder from "../UI/NoDataPlaceholder";
import { axios } from "../../utils/axios";
import { toggleSnackbar } from "../../redux/UtilityReducer/UtilityAction";
import { getApproverActionLogs } from "../../redux/DashBoardReducer/DashBoardAction";
import {
  greyOutTableCellApprover,
  locationFormatter,
  textFormatter,
} from "../../utils/helperFunctions";
import { errorNotification } from "../../utils/notificationError";

const ApproverActionLogTable = () => {
  // dispatch
  const dispatch = useDispatch();
  // selector & state
  const [openRowAction, setOpenRowAction] = useState(false);

  const { roles, user } = useSelector((state) => state?.Auth);
  const {
    approverActionLogs = [],
    selectedZone = "",
    selectedCountryLocation = "",
    listOfZones = [],
    listOfLocations = [],
  } = useSelector((state) => state?.DashBoard);

  const listZones = listOfLocations?.find(
    (data) => data.location === selectedCountryLocation
  );

  function createData(id, section, question, GPA, yesNo, date) {
    return {
      id,
      section,
      question,
      GPA,
      yesNo,
      date,
    };
  }
  const [open, setOpen] = React.useState(false);
  const [rejectComment, setRejectComment] = React.useState("");

  const rows = [
    createData(
      1,
      "Access Control",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and standards?",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global ",
      "Yes",
      "02/202022"
    ),
    createData(
      2,
      "Electronic Security",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and standards? ",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and ",
      "No",
      "03/102022"
    ),
    createData(
      3,
      "Electronic Security",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and standards? ",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and ",
      "Yes",
      "05/12/2022"
    ),
    createData(
      4,
      "Innovation",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and standards? ",
      "FSO (Facility Security Owner) is assigned and",
      "No",
      "06/08/2022"
    ),
    createData(
      5,
      "Perimeter",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global Security Sharepoint tool, policies and standards? ",
      "FSO (Facility Security Owner) is assigned and identified for this facility with access to Global",
      "Yes",
      "08/09/2022"
    ),
  ];

  const isReviewer = roles === "Reviewer";
  const isApprover = roles === "Approver";
  const [tableData, setTableData] = useState(rows);
  const [currentRow, setCurrentRow] = useState({
    id: "",
    row: {},
  });

  const [filter, setFilter] = React.useState("");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const approveActionLog = async () => {
    try {
      const resp = await axios.post("approve-actionLogs-byApprover", {
        data: approverActionLogs
          ?.filter(
            (item) => item?.action_log_main_status !== "Accepted by approver"
          )
          ?.map((item) => ({ id: item?.id, email: item?.user_email })),
        date: new Date().toISOString(),
      });
      if (resp?.data?.status) {
        dispatch(toggleSnackbar(resp?.data?.message, "success"));
        dispatch(getApproverActionLogs(selectedCountryLocation, selectedZone));
      }
    } catch (e) {
      errorNotification(e);
    }
  };

  const rejectActionLog = async () => {
    try {
      const formData = {
        data: approverActionLogs
          ?.filter(
            (item) => item?.action_log_main_status !== "Accepted by approver"
          )

          ?.map((item) => ({
            id: item?.id,
            email: item?.user_email,
            question_id: item?.question_id,
          })),
        date: new Date().toISOString(),
        role: user?.role,
        email: user?.mail,
        time: new Date(),
        comment: rejectComment,
      };
      const resp = await axios.post("reject-actionLogs-byApprover", formData);
      if (resp?.data?.status) {
        dispatch(toggleSnackbar(resp?.data?.message, "success"));
        dispatch(getApproverActionLogs(selectedCountryLocation, selectedZone));
        setOpen(false);
        setRejectComment("");
      }
      console.log(formData);
    } catch (e) {
      errorNotification(e);
    }
  };

  return (
    <>
      <div className="ActionLogTableWrapper">
        {/*TODO: Auditor view*/}
        {isApprover && (
          <div className="action-bar">
            <div className="select-wrapper my-2">
              <div className="d-flex align-items-center ml-5">
                <Grid
                  container
                  item
                  sx={{
                    padding: 1,
                    maxWidth: 300,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomSelect
                    onChange={(e) => {
                      // setResponseSet([]);
                      dispatch({
                        type: "SELECT_LOCATION",
                        payload: e.target.value,
                      });
                      dispatch({
                        type: "SET_SELECTED_COUNTRY_LOCATION",
                        payload: e.target.value || "",
                      });
                      const selectedZonesValue = listOfLocations.find(
                        (i) => i.location === e.target.value
                      )?.zones;
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: selectedZonesValue[0],
                      });
                      dispatch({
                        type: "SET_LIST_OF_ZONES",
                        payload: selectedZonesValue || [],
                      });
                    }}
                    value={selectedCountryLocation}
                    displayEmpty={false}
                    label="Select Location"
                    placeholder="Select Location"
                    options={listOfLocations?.map((item) => ({
                      value: item.location,
                      lable: item.location,
                    }))}
                  />
                </Grid>

                <Grid
                  container
                  item
                  sx={{
                    maxWidth: 300,
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomSelect
                    disabled={!selectedCountryLocation}
                    onChange={(e) => {
                      // setResponseSet([]);
                      dispatch({
                        type: "SELECT_ZONE",
                        payload: e.target.value,
                      });
                    }}
                    value={locationFormatter(selectedZone)}
                    displayEmpty={false}
                    label="Select Location Type"
                    placeholder="Select Location Type"
                    options={listZones?.zones?.map((item) => ({
                      value: locationFormatter(item),
                      lable: locationFormatter(item),
                    }))}
                  />
                </Grid>
                {/* <Grid
                  container
                  item
                  sx={{
                    padding: 1,
                    mt: {
                      xs: 1,
                      md: 2,
                    },
                    mb: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                >
                  <CustomButton
                    style={{
                      padding: "6px 50px",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "SELECT_LOCATION",
                        payload: "",
                      });

                      dispatch({
                        type: "SELECT_ZONE",
                        payload: "",
                      });
                      // setActionLogsTableData(actionLogs);
                    }}
                  >
                    RESET
                  </CustomButton>
                </Grid> */}
              </div>
            </div>
            <div className="button-wrapper my-2">
              <CustomButton
                padding={{
                  xs: "0.5rem 2rem",
                  md: "0.5rem 2rem",
                }}
                backgroundColor={SILVER}
                className="mr-3"
                onClick={() => setOpen(true)}
              >
                REJECT
              </CustomButton>
              <CustomButton
                padding={{
                  xs: "0.5rem 2rem",
                  md: "0.5rem 2rem",
                }}
                onClick={approveActionLog}
              >
                ACCEPT
              </CustomButton>
            </div>
          </div>
        )}
        <TableContainer
          component={Paper}
          className="actionLogTableSpace table-border"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  S.No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Section
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Question Answered "No"
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Gap Description
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Location
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Location Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Action Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Unique Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Latest Action
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Action Date
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approverActionLogs?.length > 0 ? (
                approverActionLogs.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.section}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "240px",
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.question}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "360px",
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.gap_description}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.location}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.location_type}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {textFormatter(row?.log_status)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.id}
                    </TableCell>
                    <TableCell
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {row?.action_log_main_status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: greyOutTableCellApprover(
                          row?.action_log_main_status
                        )
                          ? "grey"
                          : "black",
                      }}
                    >
                      {new Date(row?.action_log_main_date).toLocaleDateString()}
                    </TableCell>

                    <TableCell align="center" width={140}>
                      <div className="table-icon-wrapper">
                        {isApprover ? (
                          <CustomButton
                            padding={{
                              xs: "0 5px",
                            }}
                            onClick={() => {
                              setOpenRowAction(true);
                              setCurrentRow({
                                row: row,
                                id: row?.id,
                              });
                            }}
                          >
                            View Details
                          </CustomButton>
                        ) : (
                          <IconButton
                            sx={{ mr: 1 }}
                            style={{
                              background: YELLOW,
                              color: "#000",
                              height: 40,
                              width: 40,
                              borderRadius: 40,
                            }}
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setOpenRowAction(true);
                              setCurrentRow({
                                row: row,
                                id: row?.id,
                              });
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {/* <RowActions
                          id={row.id}
                          isReviewer={isReviewer}
                          row={row}
                        /> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" colSpan={6}>
                    <NoDataPlaceholder />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {openRowAction && (
        <RowActions
          id={currentRow?.id}
          isReviewer={isReviewer}
          isApprover={isApprover}
          row={currentRow?.row}
          open={openRowAction}
          handleClose={() => setOpenRowAction(false)}
        />
      )}
      <CustomDialogs
        open={open}
        handleClose={() => setOpen(false)}
        title={"Comment for rejecting action log"}
      >
        <TextareaAutosize
          aria-label="Action"
          minRows={3}
          placeholder="Enter here..."
          style={{ width: "100%", padding: 10 }}
          onChange={(e) => setRejectComment(e.target.value)}
          value={rejectComment}
          // disabled={isReviewer}
        />
        <CustomButton className="w-full p-1" onClick={rejectActionLog}>
          SUBMIT
        </CustomButton>
      </CustomDialogs>
    </>
  );
};
export default ApproverActionLogTable;
